import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import { initialValues, validationSchema } from '../helper';
import Editor from './Editor';
import { ImageUpload } from './ImageUpload';
import './menuCreate.scss';

function MenuCreate(props) {
  const goToList = () => {
    props.history.push(appRoute.menu);
  };

  const handleForm = async (values, { setSubmitting, setErrors }) => {
    let sendData = new FormData();
    Object.keys(values).forEach(function (key) {
      sendData.append(key, values[key]);
    });
    try {
      const res = await axios.post(apiRoute.menu, sendData);
      notifySuccess(res.data.message);
      goToList();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Add Menu</h5>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleForm}>
            {({ values, isSubmitting, setFieldValue, setErrors, setFieldTouched }) => (
              <Form>
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 col-lg-10'>
                        <div className='add-form-basic-form'>
                          <ImageUpload
                            name='icon'
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            setErrors={setErrors}
                            values={values}
                          />

                          {/* Title */}
                          <div className='form-group'>
                            <Field name='title' placeholder='Add Title' component={Input2} />
                          </div>

                          {/* Description */}
                          <div className='form-group'>
                            <Editor
                              onChange={(value) => {
                                setFieldValue('description', value);
                              }}
                            />
                            <ErrorMessage name={'description'} component='div' className='form-field-error' />
                          </div>

                          <FormSubmitBtn text='Save Menu' isSubmitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default withRouter(MenuCreate);

// class MenuEditor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       editorState: EditorState.createEmpty(),
//     };
//   }

//   onEditorStateChange = (editorState) => {
//     this.setState({
//       editorState,
//     });

//     const rawContentState = convertToRaw(editorState.getCurrentContent());
//     const markup = draftToHtml(rawContentState);
//     this.props.onChange(markup);
//   };

//   render() {
//     const { editorState } = this.state;
//     return (
//       <Editor
//         initialEditorState={editorState}
//         wrapperClassName='menu-editor-wrapper'
//         editorClassName='menu-editor'
//         toolbarClassName='toolbar'
//         onEditorStateChange={this.onEditorStateChange}
//         toolbar={{
//           options: ['blockType', 'inline', 'list', 'textAlign'],
//           inline: {
//             className: 'inline-tool',
//             component: undefined,
//             dropdownClassName: undefined,
//             options: ['bold', 'italic', 'underline'],
//           },

//           list: {
//             className: 'list-tool',
//             options: ['unordered', 'ordered'],
//           },
//           textAlign: {
//             className: 'textAlign-tool',
//           },
//           blockType: {
//             className: 'blockType-tool',
//           },
//         }}
//       />
//     );
//   }
// }
