import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import Customer_icon from '../../assets/images/icon/Customer.png';
import Dashboard_icon from '../../assets/images/icon/Dashboard.png';
import menu_icon from '../../assets/images/icon/menu.png';
import notification_icon from '../../assets/images/icon/notification.png';
import Org_icon from '../../assets/images/icon/Org.png';
import Quiz_bank_icon from '../../assets/images/icon/Quiz bank.png';
import SideMenu from '../../assets/images/icon/SideMenu.png';
import Store_icon from '../../assets/images/icon/Store.png';
import Logo_Login from '../../assets/images/Logo_Login.png';

const menu_list = (role) => [
  {
    title: 'My Dashboard',
    icon: Dashboard_icon,
    path: appRoute.dashboard,
    display: true,
  },
  {
    title: 'Organization',
    icon: Org_icon,
    path: appRoute.organization,
    display: role === 'admin',
  },
  {
    title: 'Store',
    icon: Store_icon,
    path: appRoute.store,
    display: true,
  },
  {
    title: 'Customer',
    icon: Customer_icon,
    path: appRoute.customer,
    display: true,
  },
  {
    title: 'Quiz Bank',
    icon: Quiz_bank_icon,
    path: appRoute.quizbank,
    display: true,
  },
  {
    title: 'Menu',
    icon: menu_icon,
    path: appRoute.menu,
    display: true,
  },
  {
    title: 'Notification',
    icon: notification_icon,
    path: appRoute.notification,
    display: true,
  },
];

function Sidebar(props) {
  const [mobileSidemenu, setMobileSidemenu] = useState(false);
  const { location, role, org } = props;

  const current_path_arr = location.pathname.split('/');
  let loc = current_path_arr[1] ? '/' + current_path_arr[1] : '/';

  return (
    <>
      <div id='iconMenu' onClick={() => setMobileSidemenu(true)}>
        <RenderImage image={SideMenu} class_='img-fluid' />
      </div>

      <div className={`sidenav ${mobileSidemenu ? 'sidenav' : ''}`}>
        <div className='sidenav-logo'>
          <div className='logo-left'>
            <Link to={appRoute.dashboard}>
              <div className='logo'>
                {org?.logo ? (
                  <RenderImage image={org.logo} class_='img-fluid' />
                ) : (
                  <RenderImage image={Logo_Login} class_='img-fluid' />
                )}
              </div>
            </Link>
          </div>
          <div id='iconClose' onClick={() => setMobileSidemenu(false)}>
            <FasIcon icon='faTimes' />
          </div>
        </div>
        <div className='sidenav-menu'>
          <ul className='nav sidemenu-nav' id='sidemenu-nav'>
            {(menu_list(role) || []).map(
              (menu, i) =>
                menu.display === true && (
                  <li className='nav-item' key={i}>
                    <Link to={menu.path}>
                      <div className={`nav-link text-truncate ${menu.path === loc ? 'active' : ''}`}>
                        <RenderImage image={menu.icon} class_='img-fluid' />
                        {menu.title}
                      </div>
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

const mstp = (state) => ({
  role: state?.auth?.role || common.orgRoleKey,
  org: state?.auth?.organization || null,
});

export default connect(mstp)(withRouter(Sidebar));
