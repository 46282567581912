import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useReactToPrint } from 'react-to-print';
// import { apiRoute } from 'utils/constant';

const styles = {
  qrContainer: {
    border: '2px dashed gray',
    margin: '1rem 1rem 0 1rem',
    overflowY: 'scroll',
    maxHeight: '100%',
    height: '80vh',
  },
};

export const Prints = ({ store, hideModal }) => {
  const ref = React.createRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: hideModal,
  });

  return (
    <>
      <div className='d-flex justify-content-center mt-3 w-100'>
        <button className='btn-sm btn-success' onClick={handlePrint}>
          Print QR Code
        </button>
      </div>

      <div style={styles.qrContainer}>
        <div ref={ref}>
          <h2 className='w-100 text-center font-weight-bold'>{store.organization?.name}</h2>

          <div className='d-inline-block m-2'>
            <div className='mb-2 font-weight-bold text-center h5'>{store.name}</div>
            <div className='border border-secondary'>
              <QRCode
                value={store.uid}
                size={300}
                // logoImage={apiRoute.apiAppBaseRoute + store.organization?.logo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
