import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import React from 'react';

export default class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: BraftEditor.createEditorState(null),
    };
  }

  handleChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.onChange(editorState.toHTML());
  };

  setEditorContentAsync = () => {
    const initialValue = this.props.initialValue;

    this.isLivinig &&
      initialValue &&
      this.setState({
        editorState: BraftEditor.createEditorState(initialValue),
      });
  };

  componentDidMount() {
    this.isLivinig = true;
    this.setEditorContentAsync();
  }

  componentWillUnmount() {
    this.isLivinig = false;
  }

  render() {
    const { editorState } = this.state;

    return (
      <BraftEditor
        id='editor'
        language='en'
        controls={[
          'headings',
          'font-size',
          'font-family',

          'separator',
          'bold',
          'italic',
          'underline',
          'text-color',

          'separator',
          'strike-through',
          'superscript',
          'subscript',

          'separator',
          'list-ul',
          'list-ol',

          'separator',
          'text-align',
          // 'text-indent',

          'separator',
          'hr',
          'blockquote',
          'code',
          'link',
          'remove-styles',

          'fullscreen',
        ]}
        className='menu-editor-wrapper'
        controlBarClassName='toolbar'
        placeholder='Add Description'
        value={editorState}
        onChange={this.handleChange}
      />
    );
  }
}
