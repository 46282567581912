import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { apiRoute } from 'utils/constant';

const dragOver = (e) => {
  e.preventDefault();
};

const dragEnter = (e) => {
  e.preventDefault();
};

const dragLeave = (e) => {
  e.preventDefault();
};

export function ImageUpload(props) {
  const { setFieldValue, setFieldTouched, setErrors, values, name, accept = 'image/png,image/jpeg', data } = props;

  const [icon, setIcon] = useState(null);

  const handleFile = (e, type = 'normal') => {
    e.preventDefault();

    const file = type === 'normal' ? e.currentTarget.files[0] : e.dataTransfer.files[0];

    if (!file) {
      setFieldTouched(name, true);
      setErrors({ [name]: `Only ${accept} are allowed` });
      return false;
    }

    if (![...accept.split(',')].includes(file.type)) {
      setFieldTouched(name, true);
      setErrors({ [name]: `Only ${accept} are allowed` });
      return false;
    }

    setFieldValue(name, file);

    let reader = new FileReader();
    reader.onload = (e) => {
      setIcon(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return !values.icon ? (
    <>
      <div
        className='form-group select-icon'
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={(e) => handleFile(e, 'Dnd')}
      >
        <input name={name} type='file' id={`image_${name}`} accept={accept} hidden onChange={handleFile} />

        <div className='icon-row'>
          <span>Drag &amp; drop to upload Icon or</span>
          <label htmlFor={`image_${name}`} className='btn btn-filled ml-2'>
            <FasIcon icon='faFilm' /> <span>Select File</span>
          </label>
        </div>
      </div>
      <ErrorMessage name={name} component='div' className='form-field-error mb-2 mt-n3' />
    </>
  ) : (
    <div className='form-group edit-icon'>
      <div className='edit-icon-left'>
        <div className='edit-icon-left-icon' style={{ height: '100px', width: '100px' }}>
          <RenderImage image={icon || data?.icon} class_='img-fluid' />
        </div>
        <div className='edit-icon-left-text'>
          <div className='text-top'>
            <span>{values?.icon?.name}</span>
          </div>
        </div>
      </div>
      <div
        className='edit-icon-right'
        onClick={() => {
          setFieldValue(name, null);
          setIcon(null);
        }}
      >
        <FasIcon icon='faTimes' />
      </div>
    </div>
  );
}
