import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useReactToPrint } from 'react-to-print';
import { apiRoute } from 'utils/constant';

export const Prints = ({ stores, org, hideModal }) => {
  const ref = React.createRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onAfterPrint: hideModal,
  });

  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          marginTop: '1rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div onClick={handlePrint}>
          <button className='btn-sm btn-success'>Print QR Codes</button>
        </div>
      </div>

      <div
        style={{
          border: '2px dashed gray',
          marginLeft: '1rem',
          marginRight: '1rem',
          marginTop: '1rem',
          overflowY: 'scroll',
          maxHeight: '100%',
          height: '80vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div ref={ref}>
          <div>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
                fontWeight: 'bolder',
              }}
            >
              {org.name}
            </h2>
            {stores.map((store, i) => (
              <div key={i} style={{ display: 'inline-block', margin: '10px' }}>
                <div
                  style={{
                    fontSize: '20px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {store.name}
                </div>
                <div
                  style={{
                    border: '1px solid gray',
                  }}
                >
                  {/* <QRCodeX value={store.uid} size={300} /> */}
                  <QRCode
                    value={store.uid}
                    size={300}
                    // logoImage={ org.logo}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
