import React from 'react';
import { Link } from 'react-router-dom';
import { appRoute } from 'utils/constant';

export default function Page404() {
  return (
    <div
      className='d-flex flex-column w-100 align-items-center align-middle'
      style={{ height: '100vh', verticalAlign: 'center' }}
    >
      <div style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
        <h1 className='font-weight-bold'>404</h1>
        <p>Page not found.</p>
        <br />
        <p>
          <span>Check the address or</span>
          <button className='mx-auto'>
            <Link to={appRoute.dashboard}>Go Back</Link>
          </button>
        </p>
      </div>
    </div>
  );
}
