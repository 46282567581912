import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { FarIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner, SpinnerComp } from 'components/helperComp/Spinner';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import CheckSvg from '../../../assets/images/CheckSvg.svg';

function MenuView(props) {
  const itemId = props.computedMatch.params.id;
  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));

  const [data, setData] = useState({});
  const [allOrgs, setAllOrgs] = useState([]);
  const [orgs, setOrgs] = useState([]);

  const [orgModal, setOrgModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modelLoading, setModelLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const getItemData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${apiRoute.menu}/${itemId}`);
      let r = res.data.data;
      setData(r.data);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const getOrgs = async () => {
    setModelLoading(true);
    try {
      const res = await axios.get(apiRoute.organization + '/all');
      let r = res.data.data;
      setAllOrgs(r || []);
      setOrgs(r || []);
      setModelLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleFormSubmitOrgs = async ({ org }) => {
    setOrgModal(false);

    try {
      const res = await axios.put(`/menu/${itemId}/orgs-selection`, {
        organizations: [org],
      });
      notifySuccess(res.data.message);
      setOrgModal(false);
      getItemData();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            notifyAlert(e.data.errors[0]?.msg);
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
    }
  };

  const changeStatus = async (e) => {
    let val = e.target.checked;
    try {
      const res = await axios.put(`/menu/${itemId}/status-update?status=${val}`);
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await axios.delete(`${apiRoute.menu}/${itemId}`);
      notifySuccess(res.data.message);
      goToList();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            notifyAlert(e.data.message);
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
    }
  };

  const showModal = (status) => {
    setOrgModal(status);
    getOrgs();
  };

  const goToList = () => {
    props.history.push(appRoute.menu);
  };

  const handleOrgSearch = (e) => {
    let val = e.target.value.toLowerCase();
    let filtered_orgs = allOrgs.filter((org) => org.name.toLowerCase().includes(val));
    setOrgs(filtered_orgs);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <div className='main-content'>
        <div className='main-content-left'>
          {/* ***Header*** */}
          <header className='header' id='header'>
            <div className='container-fluid'>
              <div className='header-content'>
                <div className='header-left'>
                  <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }} className='action-delete'>
                    <RenderImage image={back_arrow} class_='img-fluid' />
                  </div>

                  <h5>Menu</h5>
                </div>

                {/* {role === common.adminRoleKey && ( */}
                <div className='header-right'>
                  <div className='header-status'>
                    <span>Status:</span>

                    <div className='toggle'>
                      <div className='material-switch'>
                        <input
                          id='menu-view-switch'
                          name='someSwitchOption001'
                          type='checkbox'
                          checked={data.status}
                          onChange={changeStatus}
                        />
                        <label htmlFor='menu-view-switch' className='label-default' />
                      </div>
                    </div>
                    {data.status ? <span className='status-active'>Active</span> : <span>Inactive</span>}
                  </div>

                  {!['terms-and-conditions', 'privacy-policy'].includes(data?.slug) && (
                    <div className='header-action'>
                      <div className='action-delete' onClick={() => setDeleteModal(true)}>
                        <FarIcon icon='faTrashAlt' />
                      </div>
                    </div>
                  )}

                  <div className='header-action'>
                    <Link to={`/menu/${itemId}/edit`}>
                      <div className='action-edit'>
                        <FarIcon icon={'faEdit'} />
                      </div>
                    </Link>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </header>
          <div className='ex-padding'>
            {loading && <FsSpinner />}

            {/* *** Video box*** */}
            <section className='video-box d-inline-block position-relative w-100 p-0'>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-xl-6 col-md-6 col-sm-12'>
                    <RenderImage image={data.icon} class_='img-fluid' style={{ height: '80px', width: '80px' }} />

                    <div className='video-box-text' style={{ marginTop: '1rem' }}>
                      <p style={{ fontWeight: 'bold' }}>{data.title}</p>
                    </div>
                    <div className='video-box-text' style={{ fontSize: '14px' }}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {role === common.adminRoleKey && (
              <section className='organization-box d-inline-block position-relative w-100 pt-0 mt-5'>
                <div className='container-fluid'>
                  <div className='row align-items-center mb-4'>
                    <div className='col-8'>
                      <h5 className='sec-header'>
                        Connected Organization
                        <span>({data.organization ? 1 : 0})</span>
                      </h5>
                    </div>

                    {/* {data?.slug !== 'terms-and-conditions' && ( */}
                    <div className='col-4 text-right'>
                      <button type='button' className='btn-filled float-right' onClick={() => showModal(true)}>
                        + <span>Add Org.</span>
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                  <div className='row'>
                    <div className='col-12  col-sm-6 col-md-4 col-xl-3'>
                      {data.organization && (
                        <div className='org-box'>
                          <div
                            className='org-box-icon'
                            style={{
                              padding: 0,
                              background: 'none',
                            }}
                          >
                            <RenderImage image={data.organization?.logo} class_='img-fluid' />
                          </div>
                          <div className='org-box-title'>
                            <h6>{data.organization?.name}</h6>
                          </div>
                          <div className='org-box-detail'>
                            <div className='detail-email'>
                              <a>{data.organization?.email}</a>
                            </div>
                            <div className='detail-address'>
                              <a>{data.organization?.address}</a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
      </div>

      {orgModal && (
        <>
          <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <p className='modal-title sec-header'>New Connect Organization</p>
                  <button type='button' className='close' onClick={() => showModal(false)}>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                {modelLoading && <SpinnerComp />}
                <Formik
                  initialValues={{ org: data.organization?._id }}
                  onSubmit={handleFormSubmitOrgs}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className='modal-body'>
                        <div className='modal-search'>
                          <input
                            type='search'
                            className='search_box'
                            placeholder='Search Organization...'
                            onChange={handleOrgSearch}
                          />
                        </div>

                        <div className='organization-modal'>
                          {orgs.map((o, i) => (
                            <div className='quiz-que-box' key={`orgs_${i}`}>
                              <div className='from-row-box'>
                                <div className='form-row-right'>
                                  <p className='q-text'>{o.name}</p>
                                </div>

                                <div className='form-row-left'>
                                  <div className='custom-control custom-radios image-radio'>
                                    <input
                                      id={o._id}
                                      type='radio'
                                      name='org'
                                      value={o._id}
                                      checked={values.org === o._id}
                                      onChange={(e) => {
                                        if (e.target.checked === true) {
                                          setFieldValue('org', e.target.value);
                                        }
                                      }}
                                    />
                                    <label htmlFor={o._id}>
                                      <span>
                                        <RenderImage image={CheckSvg} class_='img-fluid' />
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='submit' className='btn-filled'>
                          Add Org.
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}

      {deleteModal && (
        <>
          <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-header pb-3'>
                  <p className='modal-title sec-header'>Are you sure you want to delete?</p>
                  <button type='button' className='close' onClick={() => setDeleteModal(false)}>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>

                {/* <div className='modal-body'></div> */}
                <div className='modal-footer mt-2'>
                  <button
                    type='submit'
                    className='btn-filled'
                    onClick={() => setDeleteModal(false)}
                    style={{ background: 'lightgray' }}
                  >
                    Cancel
                  </button>
                  <button type='submit' className='btn-filled' onClick={() => handleConfirmDelete()}>
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
}

export default withRouter(MenuView);
