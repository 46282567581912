import addImage from 'assets/images/addImage.png';
import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { notifyAlert, notifySuccess } from 'utils/notify';

function OrganizationView(props) {
  const itemId = props.computedMatch.params.id;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  let org = data.data || {};

  const getItemData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${apiRoute.organization}/${itemId}`);
      let r = res.data.data;

      setData(r);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeOrgStatus = async (status) => {
    try {
      const res = await axios.put(`${apiRoute.organization}/${itemId}/status-update?status=${status}`);
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const goToQuizbank = () => {
    props.history.push(appRoute.organization);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <header className='header' id='header'>
        <div className='container-fluid'>
          <div className='header-content'>
            <div className='header-left'>
              <div onClick={goToQuizbank} style={{ cursor: 'pointer', marginRight: '15px' }} className='action-delete'>
                <RenderImage image={back_arrow} class_='img-fluid' />
              </div>
              <h5>Organization</h5>
            </div>
            <div className='header-right'>
              <div className='header-status'>
                <span>Status:</span>
                <div className='toggle'>
                  <div className='material-switch'>
                    <input
                      id={`org_status_${org._id}`}
                      type='checkbox'
                      checked={org.status}
                      onChange={(e) => {
                        changeOrgStatus(e.target.checked);
                      }}
                    />
                    <label htmlFor={`org_status_${org._id}`} className='label-default' />
                  </div>
                </div>
                {org.status ? <span className='status-active'>Active</span> : <span>Inactive</span>}
              </div>
              <div className='header-action'>
                <Link to={`/organization/${itemId}/edit`}>
                  <div className='action-edit'>
                    <FarIcon icon='faEdit' />
                  </div>
                </Link>
              </div>

              <Link to={'/organization/create'}>
                <div className='btn-filled'>
                  + <span>Add Org.</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className='ex-padding'>
        {loading && <FsSpinner />}
        {/* *** Detail Info*** */}
        <section className='detail-info d-inlinr-block position-relative w-100'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='detail-info-box text-center'>
                  {org.logo ? (
                    <div className='detail-info-box-profile img'>
                      <RenderImage image={org.logo} class_='img-fluid' />
                    </div>
                  ) : (
                    <div className='detail-info-box-profile'>
                      <RenderImage image={addImage} class_='img-fluid' />
                    </div>
                  )}
                  <div className='detail-info-box-title'>
                    <h5 className='sec-header'>{org.name}</h5>
                  </div>
                  <div className='detail-info-box-info'>
                    <a>
                      <span>
                        <FasIcon icon='faMapMarkerAlt' />
                      </span>
                      {org.address}
                    </a>
                    <a>
                      <span>
                        <FarIcon icon='faEnvelopeOpen' />
                      </span>
                      {org.email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ***Info Tab*** */}
        <section className='info-tab d-inline-block position-relative w-100 pt-0'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='info-tab-box'>
                  <div className='info-tab-box-tabular'>
                    <div className='btn-group radio-btns' id='status' data-toggle='buttons'>
                      <label className='btn btn-on active'>
                        <input
                          type='radio'
                          defaultValue={1}
                          name='multifeatured_module[module_id][status]'
                          defaultChecked='checked'
                        />
                        <FasIcon icon='faPhoneAlt' />
                      </label>
                      <label className='btn btn-off'>
                        <input type='radio' defaultValue={0} name='multifeatured_module[module_id][status]' />
                        <FarIcon icon='faComments' />
                      </label>
                    </div>
                  </div>
                  <div className='info-tab-box-detail'>
                    <p
                      style={{
                        marginBottom: '1rem',
                        fontFamily: 'monospace',
                        borderBottom: '1px solid black',
                        width: 'fit-content',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <span>PUBLIC KEY: </span>
                      {org?.key?.public}
                    </p>
                    <p>{org.description}</p>
                  </div>
                  <section className='banner d-inline-block position-relative w-100'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-xl-4 col-lg-6 col-md-6 mb-4 mb-xl-0'>
                          <div className='banner-box'>
                            <div className='banner-content bg1'>
                              <div className='banner-content-left'>
                                <h2 className='text-xl'>{data.menu_count}</h2>
                                <p className='text-lg'>Total Menu</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-lg-6 col-md-6 mb-4 mb-xl-0'>
                          <div className='banner-box'>
                            <div className='banner-content bg2'>
                              <div className='banner-content-left'>
                                <h2 className='text-xl'>{data.store_count}</h2>
                                <p className='text-lg'>Total Store</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-lg-6 col-md-6'>
                          <div className='banner-box'>
                            <div className='banner-content bg3'>
                              <div className='banner-content-left'>
                                <h2 className='text-xl'>{data.customer_count}</h2>
                                <p className='text-lg'>Total Customer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(OrganizationView);
