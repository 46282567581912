import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Component } from 'react';

class MapContainer extends Component {
  render() {
    const { lat, lng } = this.props;
    return (
      <Map
        google={{ ...this.props.google, loaded: false }}
        zoom={12}
        initialCenter={{ lat, lng }}
        style={{ width: '100%', height: '100%' }}
        containerStyle={{ width: '100%', height: '190px' }}
      >
        <Marker mapCenter={{ lat, lng }} />
      </Map>
    );
  }
}

const fnc = (props) => ({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
  ...props,
});

export default GoogleApiWrapper(fnc)(MapContainer);
