import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { apiRoute } from 'utils/constant';
import { Typy } from 'utils/helper';
import Sidebar from './Sidebar';
import UserInfo from './UserInfo';
import logo_img from 'assets/images/defaultLogo.svg';

function Layout({ children, org }) {
  return (
    <>
      {org ? (
        <Helmet>
          <link rel='icon' href={org.logo} id='favicon' />
          <title>{org.name}</title>
        </Helmet>
      ) : (
        <Helmet>
          <link rel='icon' href={logo_img} id='favicon' />
          <title>MCC</title>
        </Helmet>
      )}

      <div className='content'>
        <UserInfo />
        <Sidebar />
        <div className='main'>{children}</div>
      </div>
    </>
  );
}

const mstp = (state) => ({
  org: Typy(state, 'auth.organization', null),
});

export default connect(mstp)(Layout);
