import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

export default function Pagination({ data, onPageChange, onPerPageChange }) {
  const {
    limit,
    page: pagination_page,
    totalPages,
    // hasNextPage,
    // hasPrevPage,
    // nextPage,
    // pagingCounter,
    // prevPage,
    // totalDocs,
  } = data;

  const [page, setPage] = useState(pagination_page);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDropdown = (val) => {
    setShowDropdown(false);
    onPerPageChange(val);
  };

  return (
    <div className='footer d-inline-block position-relative w-100'>
      <div className='container-fluid'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-md-4 pl-0'>
            <div className='list-per-page'>
              <span>List Per Page:</span>
              <div className='dropdown'>
                <button
                  className='btn btn-secondary dropdown-toggle list-page'
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  {limit}
                </button>
                {showDropdown && (
                  <div className='dropdown-menu show'>
                    <div
                      className={`dropdown-item list ${limit === 10 && 'active'}`}
                      onClick={() => handleDropdown(10)}
                    >
                      10
                    </div>
                    <div
                      className={`dropdown-item list ${limit === 15 && 'active'}`}
                      onClick={() => handleDropdown(15)}
                    >
                      15
                    </div>
                    <div
                      className={`dropdown-item list ${limit === 20 && 'active'}`}
                      onClick={() => handleDropdown(20)}
                    >
                      20
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='pagination-content'>
              <nav aria-label='Page navigation example'>
                <ReactPaginate
                  containerClassName='pagination justify-content-center'
                  pageClassName='page-item'
                  pageLinkClassName='page-link p-link'
                  activeLinkClassName='active'
                  previousLabel={
                    <span className='mr-4'>
                      <FasIcon icon='faCaretLeft' />
                    </span>
                  }
                  nextLabel={
                    <span className='ml-4'>
                      <FasIcon icon='faCaretRight' />
                    </span>
                  }
                  pageCount={totalPages || 1}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={2}
                  initialPage={page - 1}
                  onPageChange={(e) => {
                    onPageChange(e.selected + 1);
                    setPage(e.selected + 1);
                  }}
                  forcePage={page - 1}
                />
              </nav>
            </div>
          </div>
          <div className='col-md-4 text-right pr-0'>
            <div className='enter-page-num'>
              <span>Enter Page No.:</span>
              <input
                type='text'
                className='from-group'
                value={page}
                onChange={(e) => {
                  let value = e.target.value;
                  setPage(value);
                  if (value) {
                    onPageChange(value);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
