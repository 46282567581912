import customerProfile from 'assets/images/customerProfile.png';
import back_arrow from 'assets/images/icon/back arrow page.png';
import Email_support from 'assets/images/icon/Email_support.png';
import location from 'assets/images/icon/location.png';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute } from 'utils/constant';
import { Typy } from 'utils/helper';
import './customerProfile.scss';

function ViewProfile(props) {
  const user = useSelector((state) => Typy(state, 'auth.user', {})) || {};

  const org = useSelector((state) => Typy(state, 'auth.organization', {})) || {};

  const goToDashboard = () => {
    props.history.push(appRoute.dashboard);
  };

  return (
    <>
      <div>
        {/* ***Header*** */}
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToDashboard} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>My Profile</h5>
              </div>
              <div className='header-right'>
                <div className='header-action'>
                  <Link to={appRoute.editProfile}>
                    <div className='action-edit'>
                      <FarIcon icon={'faEdit'} />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          {/* ****Profile Box**** */}
          <section className='customer-profile d-inline-block position-relative w-100'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-8 mx-auto'>
                  <div className='customer-profile-box'>
                    {user.image ? (
                      <div className='customer-profile-box-img img'>
                        <RenderImage image={apiRoute.apiAppBaseRoute + user.image} class_='img-fluid' />
                      </div>
                    ) : (
                      <div className='customer-profile-box-img'>
                        <RenderImage image={customerProfile} class_='img-fluid' />
                      </div>
                    )}
                    <div className='customer-profile-box-name'>
                      <h5 className='sec-header'>{user.fullname}</h5>
                    </div>
                    <div className='customer-profile-box-info'>
                      <div className='row'>
                        <div className='col-4 col-sm-6'>
                          <div className='info-title add-padding'>
                            <RenderImage image={Email_support} class_='img-fluid' />
                            <span>Email:</span>
                          </div>
                        </div>
                        <div className='col-8 col-sm-6'>
                          <p className='info-value add-padding'>{user.email}</p>
                        </div>
                      </div>
                      <hr style={{ borderColor: '#E9F9FF' }} />
                      <div className='row'>
                        <div className='col-4 col-sm-6'>
                          <div className='info-title add-padding'>
                            <FasIcon icon='faPhoneAlt' />
                            <span>Mobile No.:</span>
                          </div>
                        </div>
                        <div className='col-8 col-sm-6'>
                          <p className='info-value add-padding'>{user.mobile_no}</p>
                        </div>
                      </div>

                      {!isEmpty(org) && (
                        <>
                          <hr style={{ borderColor: '#E9F9FF' }} />

                          <div className='row'>
                            <div className='col-4 col-sm-6'>
                              <div className='info-title add-padding'>
                                <RenderImage image={location} class_='img-fluid' />
                                <span>Address:</span>
                              </div>
                            </div>
                            <div className='col-8 col-sm-6'>
                              <p className='info-value add-padding'>{org.address}</p>
                            </div>
                          </div>

                          <hr style={{ borderColor: '#E9F9FF' }} />
                          <div className='customer-profile-box-info'>
                            <div className='row'>
                              <div className='col-4 col-sm-6'>
                                <div className='info-title add-padding'>
                                  <FarIcon icon='faBuilding' />
                                  <span>Org. Name:</span>
                                </div>
                              </div>
                              <div className='col-8 col-sm-6'>
                                <p className='info-value add-padding'>{org.name}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {!isEmpty(org) && (
                  <div className='col-8 mx-auto' style={{ marginTop: '1rem' }}>
                    <div className='customer-profile-box' style={{ padding: '30px' }}>
                      <div className='info-title mb-1' style={{ fontWeight: 'bold' }}>
                        <FasIcon icon='faMapMarkerAlt' style={{ color: '#0142da' }} />
                        <span className='ml-2'>About Org.</span>
                      </div>
                      <p style={{ fontSize: '14px', color: '#838c8f' }}>{org.description}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default withRouter(ViewProfile);
