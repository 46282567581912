import * as FarIcons from '@fortawesome/free-regular-svg-icons';
import * as FasIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function FasIcon({ icon, ...props }) {
  return <FontAwesomeIcon icon={FasIcons[icon]} {...props} />;
}

export function FarIcon({ icon, ...props }) {
  return <FontAwesomeIcon icon={FarIcons[icon]} {...props} />;
}
