import searchIcon from 'assets/images/icon/search.png';
import axios from 'axios';
import Table from 'components/core/Table';
import { PrimaryButton } from 'components/helperComp/Buttons';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiRoute, common } from 'utils/constant';
import { downloadCSV } from 'utils/exportCsv';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import {
  exportDataInitialValue,
  exportDataValidationSchema,
  exportStoreHeaders,
  exportStoreHeadersOnlyCount,
} from './helper';
import { Prints } from './Prints';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { Input2 } from 'components/core/Form/Input';
import { SpinnerComp } from 'components/helperComp/Spinner';

function StoreIndex() {
  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [qrModal, setQrModal] = useState(false);
  const [qrModalData, setQrModalData] = useState(null);
  const [exportDataModal, setExportDataModal] = useState(false);
  const [exportDataLoading, setExportDataLoading] = useState(false);
  const [exportDataModalType, setExportDataModalType] = useState(null);

  const commonColumn = [
    {
      title: 'PostCode',
      key: 'postalcode',
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Address',
      key: 'address',
    },
    {
      title: 'Total Claims',
      key: 'claims',
    },
    {
      title: 'Status',
      key: '',
      className: 'toggle',
      render: (record) => (
        <div className='material-switch'>
          <input
            key={`org_status_${record._id}`}
            id={`org_status_${record._id}`}
            type='checkbox'
            defaultChecked={record.status}
            onChange={(e) => {
              changeStatus(record._id, e.target.checked);
            }}
          />
          <label htmlFor={`org_status_${record._id}`} className='label-default' />
        </div>
      ),
    },
    {
      title: 'Action',
      key: '',
      className: 'action',
      render: (record) => (
        <>
          <Link to={`/store/${record._id}/view`}>
            <div className='view'>
              <FarIcon icon='faEye' />
            </div>
          </Link>
          <Link to={`/store/${record._id}/edit`}>
            <div className='edit mr-3'>
              <FarIcon icon='faEdit' />
            </div>
          </Link>

          <div style={{ cursor: 'pointer' }}>
            <div
              onClick={() => {
                setQrModal(true);
                setQrModalData(record);
              }}
            >
              <FasIcon icon='faQrcode' />
            </div>
          </div>
        </>
      ),
    },
  ];

  const column =
    role === common.adminRoleKey
      ? [
          {
            title: 'Store Name',
            key: 'name',
            render: (record) => <strong>{record.name}</strong>,
          },
          {
            title: 'Org. Name',
            key: 'organization.name',
          },
          ...commonColumn,
        ]
      : [
          {
            title: 'Store Name',
            key: 'name',
            render: (record) => <strong>{record.name}</strong>,
          },

          ...commonColumn,
        ];

  const handleExportDataModal = () => {
    setExportDataModal((preSatate) => !preSatate);
  };

  const getData = async (args) => {
    setLoading(true);
    const { page, limit, search: search_ } = args || {};
    try {
      const res = await axios.get(
        `${apiRoute.store}?page=${page || pagination.page}&limit=${limit || pagination.limit}&search=${
          search_ ?? search
        }`
      );
      const r = res.data.data;
      const { docs, ...pagination_ } = r;

      setData(docs);
      setPagination(pagination_);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleQrModal = (status) => {
    if (!status) {
      setQrModalData(null);
    }
    setQrModal(status);
  };

  const changeStatus = async (id, status) => {
    try {
      const res = await axios.put(`${apiRoute.store}/${id}/status-update?status=${status}`);
      notifySuccess(res.data.message);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleExportData = async (values) => {
    try {
      setExportDataLoading(true);

      let res;
      if (exportDataModalType === 'ONLY_COUNT') {
        res = await axios.get(
          `${apiRoute.store}/export-data-store-wise-count?search=${search}&start_date=${values.start_date}&end_date=${values.end_date}`
        );
      } else {
        res = await axios.get(
          `${apiRoute.store}/export-data?search=${search}&start_date=${values.start_date}&end_date=${values.end_date}`
        );
      }

      const r = res.data.data;

      let data = [];

      r.forEach((item) => {
        const {
          name,
          email,
          address,
          postalcode,
          description,
          location,
          mobile_no,
          claims,
          createdAt,
          status,
          collectionsData,
        } = item;

        data.push({
          name,
          email,
          address,
          postalcode,
          description,
          location,
          mobile_no,
          claims,
          createdAt: moment(createdAt).format('Do MMMM YYYY, h:mm:ss a'),
          status,
          customer: {
            index: '',
            name: '',
            createdAt: '',
          },
          collectionsData,
        });

        if (exportDataModalType !== 'ONLY_COUNT') {
          (collectionsData || []).forEach((item, index) => {
            data.push({
              collectionsData: {
                index: index + 1,
                name: item?.customer?.fullname,
                createdAt: moment(item?.createdAt).format('Do MMMM YYYY, h:mm:ss a'),
              },
            });
          });
        }
      });

      downloadCSV(
        data,
        exportDataModalType !== 'ONLY_COUNT' ? exportStoreHeaders : exportStoreHeadersOnlyCount,
        'Stores'
      );
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    } finally {
      setExportDataLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <h5>
                  Store <span>({(data || []).length})</span>
                </h5>
                <div className='search'>
                  <div className='search-input-div' id='searchInputDiv'>
                    <input
                      type='search'
                      className='search-input'
                      placeholder='Search Store'
                      onChange={(e) => {
                        setSearch(e.target.value);
                        getData({ search: e.target.value });
                      }}
                    />
                  </div>
                  <span className='search-icon' id='searchIcon'>
                    <RenderImage image={searchIcon} class_='img-fluid' />
                  </span>
                </div>
              </div>
              <div className='header-right'>
                {role === common.orgRoleKey && (
                  <>
                    <PrimaryButton
                      text='Export Data (Only Count)'
                      onClick={() => {
                        setExportDataModal(true);
                        setExportDataModalType('ONLY_COUNT');
                      }}
                    />
                    <PrimaryButton
                      text='Export Data'
                      onClick={() => {
                        setExportDataModal(true);
                        setExportDataModalType('ALL_DATA');
                      }}
                    />
                  </>
                )}

                <Link to={'/store/create'}>
                  <button type='button' className='btn-filled'>
                    + <span>Add Store</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          <Table
            columns={column}
            dataSource={data}
            pagination={pagination}
            onPageChange={(page) => getData({ page })}
            onPerPageChange={(limit) => getData({ limit })}
            loading={loading}
          />
        </div>

        {qrModal && qrModalData && (
          <>
            <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
              <div
                className='modal-dialog modal-dialog-centered'
                role='document'
                style={{
                  maxWidth: '1120px',
                }}
              >
                <div className='modal-content' style={{ paddingBottom: '1rem' }}>
                  <div
                    className='modal-header'
                    style={{
                      paddingBottom: '10px',
                    }}
                  >
                    <p className='modal-title sec-header'>{qrModalData.name} QR Code</p>
                    <button type='button' className='close' onClick={() => handleQrModal(false)}>
                      <span aria-hidden='true'>×</span>
                    </button>
                  </div>

                  <Prints store={qrModalData} hideModal={() => handleQrModal(false)} />
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade show'></div>
          </>
        )}

        <Modal isOpen={exportDataModal} toggle={handleExportDataModal} backdrop='static' centered>
          <ModalHeader toggle={handleExportDataModal}>Export store's data</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={exportDataInitialValue}
              validationSchema={exportDataValidationSchema}
              onSubmit={handleExportData}
            >
              {() => (
                <Form>
                  <div className='row'>
                    <div className='col-6'>
                      <label> Start date </label>
                      <Field type='date' name='start_date' placeholder='Start date' component={Input2} />
                    </div>
                    <div className='col-6'>
                      <label> End date </label>
                      <Field type='date' name='end_date' placeholder='End date' component={Input2} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-end mt-4'>
                    <PrimaryButton
                      text={
                        exportDataLoading ? (
                          <SpinnerComp
                            style={{
                              position: 'relative',
                              margin: 'auto auto',
                              top: 0,
                              left: 0,
                              height: '25px',
                              width: '25px',
                            }}
                          />
                        ) : (
                          'Export'
                        )
                      }
                      type='submit'
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default StoreIndex;
