import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { FarIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner, SpinnerComp } from 'components/helperComp/Spinner';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import CheckSvg from '../../../assets/images/CheckSvg.svg';
import './quizView.scss';

function QuizbankView(props) {
  const itemId = props.computedMatch.params.id;
  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));
  const [data, setData] = useState({});
  const [allOrgs, setAllOrgs] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [orgIds, setOrgIds] = useState([]);
  const [orgModal, setOrgModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modelLoading, setModelLoading] = useState(false);

  const getItemData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${apiRoute.quiz}/${itemId}`);
      let r = res.data.data;
      setData(r.data);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const getOrgs = async () => {
    setModelLoading(true);
    try {
      const res = await axios.get(apiRoute.organization);
      let r = res.data.data;
      setAllOrgs(r.docs);
      setOrgs(r.docs);
      setOrgIds([...r.docs].map((o) => o._id));
      setModelLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleFormSubmitOrgs = async ({ organizations }) => {
    setOrgModal(false);
    let formdata = organizations.filter((value) => value);

    try {
      const res = await axios.put(`/quiz/${itemId}/update-orgs`, {
        organizations: formdata,
      });
      notifySuccess(res.data.message);
      setOrgModal(false);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeStatus = async (e) => {
    let val = e.target.checked;
    try {
      const res = await axios.put(`/quiz/${itemId}/status-update?status=${val}`);
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const showModal = (status) => {
    setOrgModal(status);
    getOrgs();
  };

  const goToQuizbank = () => {
    props.history.push(appRoute.quizbank);
  };

  const handleOrgSearch = (e) => {
    let val = e.target.value.toLowerCase();
    let filtered_orgs = allOrgs.map((org) => (org.name.toLowerCase().includes(val) ? org : null));
    setOrgs(filtered_orgs);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <div className='main-content'>
        <div className='main-content-left'>
          {/* ***Header*** */}
          <header className='header' id='header'>
            <div className='container-fluid'>
              <div className='header-content'>
                <div className='header-left'>
                  <div
                    onClick={goToQuizbank}
                    style={{ cursor: 'pointer', marginRight: '15px' }}
                    className='action-delete'
                  >
                    <RenderImage image={back_arrow} class_='img-fluid' />
                  </div>

                  <h5>{data.name}</h5>
                </div>
                {(data.createdBy !== null || role === common.adminRoleKey) && (
                  <div className='header-right'>
                    <div className='header-status'>
                      <span>Status:</span>
                      <div className='toggle'>
                        <div className='material-switch'>
                          <input
                            id='quiz_view_switch'
                            name='someSwitchOption001'
                            type='checkbox'
                            checked={data.status}
                            onChange={changeStatus}
                          />
                          <label htmlFor='quiz_view_switch' className='label-default' />
                        </div>
                      </div>
                      {data.status ? <span className='status-active'>Active</span> : <span>Inactive</span>}
                    </div>
                    <div className='header-action'>
                      <Link to={`/quizbank/${itemId}/edit`}>
                        <div className='action-edit'>
                          <FarIcon icon={'faEdit'} />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          <div className='ex-padding'>
            {loading && <FsSpinner />}

            {/* *** Video box*** */}
            <section className='video-box d-inline-block position-relative w-100 p-0' style={{ marginBottom: '2rem' }}>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-xl-6 col-md-6 col-sm-12'>
                    <div className='video-box-img'>
                      <ReactPlayer
                        url={apiRoute.apiAppBaseRoute + data.video}
                        controls={true}
                        playing={false}
                        className='video-img'
                      />
                    </div>
                    <div className='video-box-detail'>
                      <span>
                        <Moment date={data.updatedAt} fromNow />
                      </span>
                      <span>{parseInt(data.videoSize).toFixed(1)}MB</span>
                    </div>
                    <div className='video-box-text'>
                      <p>{data.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ***Organization box*** */}

            {role === common.adminRoleKey && data.createdBy === null && (
              <section className='organization-box d-inline-block position-relative w-100 pt-0'>
                <div className='container-fluid'>
                  <div className='row align-items-center mb-4'>
                    <div className='col-8'>
                      <h5 className='sec-header'>
                        Connected Organization
                        <span>({data.org ? data.org.length : 0})</span>
                      </h5>
                    </div>
                    <div className='col-4 text-right'>
                      <button type='button' className='btn-filled float-right' onClick={() => showModal(true)}>
                        + <span>Add Org.</span>
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    {(data.org || []).map((org, index) => (
                      <div className='col-xl-4' key={`org_${index}`}>
                        <div className='org-box'>
                          <div className='org-box-icon'>
                            <RenderImage image={apiRoute.apiAppBaseRoute + org.logo} class_='img-fluid' />
                          </div>
                          <div className='org-box-title'>
                            <h6>{org.name}</h6>
                          </div>
                          <div className='org-box-detail'>
                            <div className='detail-email'>
                              <a>{org.email}</a>
                            </div>
                            <div className='detail-address'>
                              <a>{org.address}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <div className='main-content-right'>
          <div className='form-group'>
            <h5 className='sec-header'>
              All Questions
              <span>({data.questions ? data.questions.length : 0})</span>
            </h5>
          </div>

          {(data.questions || []).map((q, i) => (
            <div className='quiz-que-box' key={`question_${i}`}>
              <form>
                <div className='from-row-box que'>
                  <div className='form-group form-row-left '>
                    <span>Q{i + 1}:</span>
                  </div>
                  <div className='form-group form-row-right ml-2'>
                    <p>{q.title}</p>
                  </div>
                </div>
                <div className='radio-group'>
                  {(q.options || []).map((opt, ind) => (
                    <div className='from-row-box' key={`value_${i}_${ind}`}>
                      <div className='form-group form-row-left'>
                        <div className='custom-control custom-radios image-radio'>
                          <input
                            type='radio'
                            id={`value_${i}_${ind}`}
                            name='color'
                            defaultChecked={opt.isAns}
                            disabled={true}
                          />
                          <label htmlFor={ind}>
                            <span>
                              <RenderImage image={CheckSvg} class_='img-fluid' />
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className='form-group form-row-right'>
                        <p className='q-text'>{opt.value}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='form-group form-row-right  ml-2'>
                  <p>
                    <strong>Question message:</strong> {q.message}
                  </p>
                </div>
              </form>
            </div>
          ))}
        </div>
      </div>

      {orgModal && (
        <>
          <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
            <div className='modal-dialog modal-dialog-centered' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <p className='modal-title sec-header'>New Connect Organization</p>
                  <button type='button' className='close' onClick={() => showModal(false)}>
                    <span aria-hidden='true'>×</span>
                  </button>
                </div>
                {modelLoading && <SpinnerComp />}
                <Formik
                  initialValues={{
                    organizations: orgIds.map((o) => (data.org.map((or) => or._id).includes(o) ? o : null)),
                  }}
                  onSubmit={handleFormSubmitOrgs}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div className='modal-body'>
                        <div className='modal-search'>
                          <input
                            type='search'
                            className='search_box'
                            placeholder='Search Organization...'
                            onChange={handleOrgSearch}
                          />
                        </div>

                        <div className='organization-modal'>
                          {orgs.map(
                            (o, i) =>
                              o && (
                                <div className='quiz-que-box' key={`orgs_${i}`}>
                                  <div className='from-row-box' key={`orgs_list_${i}`}>
                                    <div className='form-row-right'>
                                      <p className='q-text'>{o.name}</p>
                                    </div>

                                    <div className='form-row-left'>
                                      <div className='custom-control custom-radios image-radio'>
                                        <input
                                          id={`org_checkbox_${i}`}
                                          type='checkbox'
                                          name='organizations'
                                          value={o._id}
                                          checked={values.organizations[i]}
                                          onChange={(e) => {
                                            if (e.target.checked === true) {
                                              setFieldValue(`organizations.${i}`, e.target.value);
                                            } else {
                                              setFieldValue(`organizations.${i}`, null);
                                            }
                                          }}
                                        />
                                        <label htmlFor={`org_checkbox_${i}`}>
                                          <span>
                                            <RenderImage image={CheckSvg} class_='img-fluid' />
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button type='submit' className='btn-filled'>
                          Add Org.
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className='modal-backdrop fade show'></div>
        </>
      )}
    </>
  );
}

export default withRouter(QuizbankView);
