import { validationMsg as vm } from 'utils/constant';
import * as Yup from 'yup';

export const initialValues = {
  image: null,
  name: '',
  email: '',
  mobile_no: '',
  address: '',
  country: '',
  postalcode: '',
  description: '',
  lat: '',
  long: '',
  // location: {
  //   lat: '',
  //   long: '',
  // },
  organization: '',
  status: false,
};

const commonValidation = {
  name: Yup.string().required(vm.name.required),
  mobile_no: Yup.string().required(vm.mobile_no.required),
  address: Yup.string().required(vm.address.required),

  image: Yup.string().nullable(),
  email: Yup.string().email(vm.email.invalid),
  country: Yup.string(),
  postalcode: Yup.string(),
  description: Yup.string(),
  // image: Yup.string().nullable().required(vm.image.required),
  // email: Yup.string().email(vm.email.invalid).required(vm.email.required),
  // country: Yup.string().required(vm.country.required),
  // postalcode: Yup.string().required(vm.postalcode.required),
  // description: Yup.string().required(vm.description.required),
  lat: Yup.number()
    .min(-90, vm.location.lat.beetween)
    .max(90, vm.location.lat.beetween)
    .required(vm.location.lat.required),
  long: Yup.number()
    .min(-180, vm.location.long.beetween)
    .max(180, vm.location.long.beetween)
    .required(vm.location.long.required),
  // location: Yup.object().shape({
  //   lat: Yup.number().required(vm.location.lat.required),
  //   long: Yup.number().required(vm.location.long.required),
  // }),
  status: Yup.string().required(vm.status.required),
};

export const validationSchema = Yup.object().shape({
  ...commonValidation,
  organization: Yup.string(),
});

export const validationSchema_admin = Yup.object().shape({
  ...commonValidation,
  organization: Yup.string().required(vm.organization.required),
});

export const exportStoreHeaders = [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'country', label: 'country' },
  { key: 'address', label: 'Address' },
  { key: 'postalcode', label: 'Postcode' },
  { key: 'description', label: 'Description' },
  { key: 'location.lat', label: 'Latitude' },
  { key: 'location.long', label: 'Longitude' },
  { key: 'mobile_no', label: 'Mobile number' },
  { key: 'claims', label: 'Claims' },
  { key: 'createdAt', label: 'Createdat' },
  { key: 'status', label: 'Status' },

  { key: '', label: '' },
  { key: '', label: '' },
  { key: 'collectionsData.index', label: 'Sr.No.' },
  { key: 'collectionsData.name', label: 'Customer Name' },
  { key: 'collectionsData.createdAt', label: 'Collection date' },
];

export const exportStoreHeadersOnlyCount = [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'country', label: 'country' },
  { key: 'address', label: 'Address' },
  { key: 'postalcode', label: 'Postcode' },
  { key: 'description', label: 'Description' },
  { key: 'location.lat', label: 'Latitude' },
  { key: 'location.long', label: 'Longitude' },
  { key: 'mobile_no', label: 'Mobile number' },
  { key: 'createdAt', label: 'Createdat' },
  { key: 'status', label: 'Status' },
  { key: 'claims', label: 'Claims' },
];

export const exportDataInitialValue = {
  start_date: '',
  end_date: '',
};

export const exportDataValidationSchema = Yup.object().shape({
  start_date: Yup.string(),
  end_date: Yup.string(),
});
