import back_arrow from 'assets/images/icon/back arrow page.png';
import Delete_Action from 'assets/images/icon/Delete_Action.png';
import Edit_Action from 'assets/images/icon/Edit_Action.png';
import axios from 'axios';
import { Input } from 'components/core/Form/Input';
import TextArea from 'components/core/Form/TextArea';
import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import CheckSvg from '../../../assets/images/CheckSvg.svg';
import { initialValues, initialValuesQue_, validationSchema, validationSchemaQue } from '../helper';
import './addQuiz.scss';

const dragOver = (e) => {
  e.preventDefault();
};

const dragEnter = (e) => {
  e.preventDefault();
};

const dragLeave = (e) => {
  e.preventDefault();
};

function QuizbankCreate(props) {
  const [questions, setQuestions] = useState([]);
  const [initialValuesQue, setInitialValuesQue] = useState(initialValuesQue_);
  const [video, setVideo] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const formRef = useRef();
  const videoRef = useRef();

  const handleAddQue = (values, { setSubmitting, resetForm }) => {
    setQuestions([...questions, values]);
    setSubmitting(false);
    setFormSubmitting(false);
    resetForm();
    setInitialValuesQue(initialValuesQue_);
  };

  const deleteQuestion = (id) => {
    setQuestions(questions.filter((q, i) => i !== id));
  };

  const editQuestion = (id) => {
    setInitialValuesQue(questions[id]);
    setQuestions(questions.filter((q, i) => i !== id));
  };

  const fileDrop = (e, setFieldValue) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    setFieldValue('video', files[0]);
  };

  const goToQuizbank = () => {
    props.history.push(appRoute.quizbank);
  };

  const handleFormSubmit = async (formdata, { setSubmitting, setErrors }) => {
    if (questions.length < 1) {
      notifyAlert('At least one question is required');
      return;
    }
    setFormSubmitting(formRef.current.isSubmitting);
    formdata['questions'] = JSON.stringify(
      questions.map(({ title, options, answer, message }) => ({
        title,
        message,
        options: options.map((opt, index) => ({
          value: opt.value,
          isAns: index == answer,
        })),
      }))
    );

    let send_data = new FormData();
    Object.keys(formdata).forEach(function (key) {
      send_data.append(key, formdata[key]);
    });

    try {
      const res = await axios.post(apiRoute.quiz, send_data);
      notifySuccess(res.data.message);
      goToQuizbank();
    } catch (err) {
      const e = err.response;

      if (e) {
        switch (e.status) {
          case 422:
            let error_obj = getErrorObj(e.data.errors);

            let duplicate_opt = error_obj['questions[0].options'];
            duplicate_opt && notifyAlert('One of Question has duplicate options. ');

            setErrors(error_obj);
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
      setFormSubmitting(false);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.seekTo(1, 'seconds');
    }
  }, [video]);

  return (
    <>
      <div className='main-content'>
        <div className='main-content-left'>
          {/* ***Header*** */}
          <header className='header' id='header'>
            <div className='container-fluid'>
              <div className='header-content'>
                <div className='header-left'>
                  <div onClick={goToQuizbank} style={{ cursor: 'pointer', marginRight: '15px' }}>
                    <RenderImage image={back_arrow} class_='img-fluid' />
                  </div>
                  <h5>Add Quiz</h5>
                </div>
                <div className='header-right'>
                  <FormSubmitBtn
                    text='Save Quiz'
                    className='btn-save mx-auto mt-2 w-100'
                    isSubmitting={formSubmitting}
                    style={{ minWidth: '105px' }}
                    onClick={() => formRef.current.submitForm()}
                  />
                </div>
              </div>
            </div>
          </header>

          <div className='ex-padding'>
            {/* ***Add Quiz*** */}
            <section className='add-quiz d-inline-block position-relative w-100 '>
              <div className='row'>
                <div className='col-12 form'>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={handleFormSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <>
                          <div className='form-group'>
                            <h5 className='sec-header'>Add Video</h5>
                          </div>

                          <div className='form-group'>
                            <Input name='name' placeholder='Enter Video name' />
                          </div>
                          <div className='form-group'>
                            <TextArea name='description' rows={3} placeholder='Add Quiz description' />
                          </div>

                          {!values.video ? (
                            <div
                              className='form-group select-video'
                              onDragOver={dragOver}
                              onDragEnter={dragEnter}
                              onDragLeave={dragLeave}
                              onDrop={(e) => fileDrop(e, setFieldValue)}
                            >
                              {/* actual upload which is hidden */}
                              <input
                                name='video'
                                type='file'
                                id='actual-btn'
                                accept='video/*'
                                hidden
                                onChange={(event) => {
                                  let file = event.currentTarget.files[0];
                                  setFieldValue('video', file);

                                  let reader = new FileReader();
                                  reader.onload = (e) => {
                                    setVideo(e.target.result);
                                  };
                                  reader.readAsDataURL(file);
                                }}
                              />

                              <div className='video-row'>
                                <span id='file-chosen'>Drag &amp; drop to upload or</span>
                                <label htmlFor='actual-btn' className='btn btn-filled'>
                                  <FasIcon icon='faFilm' />
                                  Select File
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className='form-group edit-video'>
                              <div className='edit-video-left'>
                                <div className='edit-video-left-icon' style={{ height: '100px', width: '100px' }}>
                                  <ReactPlayer
                                    url={video}
                                    controls={false}
                                    playing={false}
                                    height={100}
                                    width={100}
                                    ref={videoRef}
                                  />
                                </div>
                                <div className='edit-video-left-text'>
                                  <div className='text-top'>
                                    <span>{values.video && values.video.name}</span>
                                  </div>
                                  <div className='text-bottom'>
                                    <p>
                                      {values.video && (values.video.size / (1024 * 1024)).toFixed(1)}
                                      MB
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className='edit-video-right'
                                onClick={() => {
                                  setFieldValue('video', null);
                                  setVideo(null);
                                }}
                              >
                                <FasIcon icon='faTimes' />
                              </div>
                            </div>
                          )}

                          <ErrorMessage name='video' component='div' className='form-field-error' />

                          <div className='form-group' style={questions.length < 2 ? { display: 'none' } : {}}>
                            <h5 className='sec-header'>Set Question Limit</h5>
                          </div>
                          <div className='form-group' style={questions.length < 2 ? { display: 'none' } : {}}>
                            <div className='range-wrap'>
                              <input
                                name='questionsLimit'
                                type='range'
                                className='range'
                                min={1}
                                max={questions.length}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setFieldValue('questionsLimit', value);
                                  if (value < parseInt(values.passingScore)) {
                                    setFieldValue('passingScore', value);
                                  }
                                }}
                              />
                              <output
                                className='bubble'
                                style={{
                                  left: `calc(${((values.questionsLimit - 1) * 100) / (questions.length - 1)}% + (${
                                    8 - values.questionsLimit * 0.15
                                  }px))`,
                                }}
                              >
                                {values.questionsLimit}
                              </output>
                            </div>
                          </div>

                          <div className='form-group' style={questions.length < 2 ? { display: 'none' } : {}}>
                            <h5 className='sec-header'>Set Passing Score</h5>
                          </div>
                          <div className='form-group ' style={questions.length < 2 ? { display: 'none' } : {}}>
                            <div className='range-wrap'>
                              <input
                                name='passingScore'
                                type='range'
                                className='range'
                                min={1}
                                max={parseInt(values.questionsLimit)}
                                onChange={(e) => {
                                  setFieldValue('passingScore', e.target.value);
                                }}
                              />
                              <output
                                className='bubble'
                                style={{
                                  left: `calc(${((values.passingScore - 1) * 100) / (questions.length - 1)}% + (${
                                    8 - values.passingScore * 0
                                  }px))`,
                                }}
                              >
                                {values.passingScore}
                              </output>
                            </div>
                          </div>
                        </>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={initialValuesQue}
                    validationSchema={validationSchemaQue}
                    onSubmit={handleAddQue}
                    enableReinitialize={true}
                  >
                    {({ values, isSubmitting }) => (
                      <Form>
                        <>
                          <div className='form-group'>
                            <h5 className='sec-header'>Add Question</h5>
                          </div>
                          <div className='from-row-box'>
                            <div className='form-group form-row-left'>Q1:</div>
                            <div className='form-group form-row-right'>
                              <TextArea
                                name='title'
                                rows={3}
                                placeholder='Enter Question'
                                className='form-control w-100 textarea'
                              />
                            </div>
                          </div>

                          <FieldArray
                            name='options'
                            render={(arrayHelpers) => (
                              <div>
                                {values.options.map((_, index) => (
                                  <div className='from-row-box' key={`que_opt_${index}`}>
                                    <div className='form-group form-row-left'>
                                      <div className='custom-control custom-radios image-radio'>
                                        <Input type='radio' id={index} name='answer' value={index} />
                                        <label htmlFor={index}>
                                          <span>
                                            <RenderImage image={CheckSvg} class_='img-fluid' />
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className='form-group form-row-right'>
                                      <Input
                                        name={`options.${index}.value`}
                                        className='form-control'
                                        placeholder={`Enter option ${index + 1}`}
                                      />
                                    </div>
                                  </div>
                                ))}

                                <div
                                  onClick={() => arrayHelpers.push('')}
                                  style={{
                                    padding: '8px',
                                    backgroundColor: '#f9fdff',
                                    border: '2px solid #1c42da',
                                    borderRadius: '50%',
                                    height: '35px',
                                    width: '35px',
                                    margin: '1rem auto 2rem auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FasIcon
                                    icon='faPlus'
                                    style={{
                                      color: '#1c42da',
                                      fontSize: '25px',
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          />

                          <hr />
                          <div className='from-row-box'>
                            <div className='form-group form-row-right' style={{ width: '100%' }}>
                              <TextArea
                                name='message'
                                rows={2}
                                placeholder='Enter Question Popup message'
                                className='form-control w-100 textarea'
                                style={{ fontWeight: 'normal' }}
                              />
                            </div>
                          </div>
                          <button type='submit' className='btn-filled mx-auto mt-2' disabled={isSubmitting}>
                            Add Question
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className='main-content-right'>
          {questions.map((que, index) => (
            <div className='quiz-que-box' key={`questions_${index}`}>
              <div className='from-row-box que'>
                <div className='form-group form-row-left '>
                  <span>Q{index + 1}:</span>
                </div>
                <div className='form-group form-row-right  ml-2'>
                  <p>{que.title}</p>
                </div>
                <div className='form-row-menu'>
                  <div className='menu-action'>
                    <a className='action-edit' onClick={() => editQuestion(index)}>
                      <RenderImage image={Edit_Action} class_='img-fluid' />
                    </a>
                    <a className='action-delete' onClick={() => deleteQuestion(index)}>
                      <RenderImage image={Delete_Action} class_='img-fluid' />
                    </a>
                  </div>
                </div>
              </div>

              <div className='radio-group'>
                {que.options.map((opt, index2) => (
                  <div className='from-row-box' key={`questions_list_${index2}`}>
                    <div className='form-group form-row-left'>
                      <div className='custom-control custom-radios image-radio'>
                        <input
                          type='radio'
                          id={`que_${index}_${index2}`}
                          name={`answer_${index}`}
                          value={index2}
                          checked={que.answer == index2}
                          disabled
                        />
                        <label htmlFor={`que_${index}_${index2}`}>
                          <span>
                            <RenderImage image={CheckSvg} class_='img-fluid' />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='form-group form-row-right'>
                      <p className='q-text'>{opt.value}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className='form-group form-row-right  ml-2'>
                <p>
                  <strong>Question message:</strong> {que.message}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default withRouter(QuizbankCreate);
