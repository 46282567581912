import { compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers';

// create history
export const history = require('history').createBrowserHistory();

// create store
export const store =
  process.env.NODE_ENV === 'production'
    ? createStore(rootReducer)
    : createStore(rootReducer, compose(composeWithDevTools()));
