import axios from 'axios';
import Cookie from 'js-cookie';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { appRoute, common } from '../utils/constant';

// AR = Authentication Routes
export default function AR(props) {
  const Component = props.component;
  const cookie_token = Cookie.get(common.authTokenKey);
  const role = Cookie.get(common.roleKey);

  if (!(cookie_token && role)) {
    axios.defaults.headers.common['Authorization'] = null;
    Cookie.remove(common.authTokenKey);
    Cookie.remove(common.roleKey);
  }

  return cookie_token && role ? <Redirect to={{ pathname: appRoute.dashboard }} /> : <Component {...props} />;
}
