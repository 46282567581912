import { store } from 'react-notifications-component';

const notify_config = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: { duration: 5000 },
};

export function notifySuccess(msg) {
  msg &&
    store.addNotification({
      message: msg,
      type: 'success',
      ...notify_config,
    });
}

export function notifyAlert(msg) {
  msg &&
    store.addNotification({
      message: msg,
      type: 'danger',
      ...notify_config,
    });
}

export function notifyInfo(msg) {
  msg &&
    store.addNotification({
      message: msg,
      type: 'info',
      ...notify_config,
    });
}

export function notifyWarning(msg) {
  msg &&
    store.addNotification({
      message: msg,
      type: 'warning',
      ...notify_config,
    });
}

export function notify(msg) {
  msg &&
    store.addNotification({
      message: msg,
      type: 'default',
      ...notify_config,
    });
}
