import { SpinnerComp } from './Spinner';

export default function FormSubmitBtn({ text, isSubmitting, ...props }) {
  // isSubmitting = true;
  return (
    <button className='btn-filled mx-auto' type='submit' disabled={isSubmitting} {...props}>
      {isSubmitting ? (
        <SpinnerComp
          style={{
            position: 'relative',
            margin: 'auto auto',
            top: 0,
            left: 0,
            height: '25px',
            width: '25px',
          }}
        />
      ) : (
        text || 'Save'
      )}
    </button>
  );
}
