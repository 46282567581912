import ForgotPassword from 'components/Auth/ForgotPassword';
import Login from 'components/Auth/Login';
import NewPassword from 'components/Auth/NewPassword';
import Customer from 'components/Customer';
import CustomerView from 'components/Customer/View';
import Dashboard from 'components/Dashboard';
import Page404 from 'components/Error/Page404';
import Menu from 'components/Menu';
import MenuCreate from 'components/Menu/Create';
import MenuEdit from 'components/Menu/Edit';
import MenuView from 'components/Menu/View';
import Notification from 'components/Notification';
import NotificationSend from 'components/Notification/Send';
import Organization from 'components/Organization';
import OrganizationCreate from 'components/Organization/Create';
import OrganizationEdit from 'components/Organization/Edit';
import OrganizationRegQuestions from 'components/Organization/RegQuestions';
import OrganizationView from 'components/Organization/View';
import Quizbank from 'components/Quizbank';
import QuizbankCreate from 'components/Quizbank/Create';
import QuizbankEdit from 'components/Quizbank/Edit';
import QuizbankView from 'components/Quizbank/View';
import Setting from 'components/Setting';
import Store from 'components/Store';
import StoreCreate from 'components/Store/Create';
import StoreEdit from 'components/Store/Edit';
import StoreView from 'components/Store/View';
import ChangePassword from 'components/User/ChangePassword';
import EditProfile from 'components/User/EditProfile';
import ViewProfile from 'components/User/ViewProfile';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { appRoute } from '../utils/constant';
import AR from './AR';
import PR from './PR';

function AppRoutes() {
  return (
    <Switch>
      {/* Auth */}
      <AR exact path={appRoute.login} component={Login} />
      <AR exact path={appRoute.forgotPassword} component={ForgotPassword} />
      <AR exact path={appRoute.newPassword} component={NewPassword} />

      {/* User */}
      <PR exact path={appRoute.editProfile} component={EditProfile} />
      <PR exact path={appRoute.viewProfile} component={ViewProfile} />
      <PR exact path={appRoute.changePassword} component={ChangePassword} />
      <PR exact path={appRoute.setting} component={Setting} />

      {/* Dashboard */}
      <PR exact path={appRoute.dashboard} component={Dashboard} />

      {/* Organization */}
      <PR exact path={appRoute.organization} component={Organization} />
      <PR exact path={appRoute.organizationCreate} component={OrganizationCreate} />
      <PR exact path={appRoute.organizationView} component={OrganizationView} />
      <PR exact path={appRoute.organizationEdit} component={OrganizationEdit} />
      <PR exact path={appRoute.organizationRegQuestions} component={OrganizationRegQuestions} />

      {/* Store */}
      <PR exact path={appRoute.store} component={Store} />
      <PR exact path={appRoute.storeCreate} component={StoreCreate} />
      <PR exact path={appRoute.storeView} component={StoreView} />
      <PR exact path={appRoute.storeEdit} component={StoreEdit} />

      {/* Customer */}
      <PR exact path={appRoute.customer} component={Customer} />
      <PR exact path={appRoute.customerView} component={CustomerView} />

      {/* Quizbank */}
      <PR exact path={appRoute.quizbank} component={Quizbank} />
      <PR exact path={appRoute.quizbankCreate} component={QuizbankCreate} />
      <PR exact path={appRoute.quizbankView} component={QuizbankView} />
      <PR exact path={appRoute.quizbankEdit} component={QuizbankEdit} />

      {/* Menu */}
      <PR exact path={appRoute.menu} component={Menu} />
      <PR exact path={appRoute.menuCreate} component={MenuCreate} />
      <PR exact path={appRoute.menuEdit} component={MenuEdit} />
      <PR exact path={appRoute.menuView} component={MenuView} />

      {/* Notification */}
      <PR exact path={appRoute.notification} component={Notification} />
      <PR exact path={appRoute.notificationSend} component={NotificationSend} />

      {/* Other */}
      <Redirect exact from={appRoute.homepage} to={appRoute.dashboard} />
      <Route component={Page404} />
    </Switch>
  );
}

export default AppRoutes;
