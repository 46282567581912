import addImage from 'assets/images/addImage.png';
import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import GoogleMap from './GoogleMap';

function StoreView(props) {
  const itemId = props.computedMatch.params.id;

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  let store = data.data || {};

  const getItemData = async () => {
    setLoading(true);

    try {
      const res = await axios.get(`${apiRoute.store}/${itemId}`);
      let r = res.data.data;
      setData(r);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeItemStatus = async (status) => {
    try {
      const res = await axios.put(`${apiRoute.store}/${itemId}/status-update?status=${status}`);
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const goToList = () => {
    props.history.push(appRoute.store);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <header className='header' id='header'>
        <div className='container-fluid'>
          <div className='header-content'>
            <div className='header-left'>
              <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }} className='action-delete'>
                <RenderImage image={back_arrow} class_='img-fluid' />
              </div>
              <h5>Store</h5>
            </div>
            <div className='header-right'>
              <div className='header-status'>
                <span>Status:</span>
                <div className='toggle'>
                  <div className='material-switch'>
                    <input
                      id={`store_status_${store._id}`}
                      type='checkbox'
                      checked={store.status}
                      onChange={(e) => {
                        changeItemStatus(e.target.checked);
                      }}
                    />
                    <label htmlFor={`store_status_${store._id}`} className='label-default' />
                  </div>
                </div>
                <span> {store.status ? <span className='status-active'>Active</span> : <span>Inactive</span>}</span>
                <span>{store?.statusUpdatedAt && `( ${new Date(store?.statusUpdatedAt).toLocaleString()} )`} </span>
              </div>

              <div className='header-action'>
                <Link to={`/store/${itemId}/edit`}>
                  <div className='action-edit'>
                    <FarIcon icon='faEdit' />
                  </div>
                </Link>
              </div>

              <Link to={'/store/create'}>
                <div className='btn-filled'>
                  + <span>Add Store</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className='ex-padding'>
        {loading && <FsSpinner />}
        {/* *** Detail Info*** */}
        <section className='detail-info d-inlinr-block position-relative w-100'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='detail-info-box text-center'>
                  {store.image ? (
                    <div className='detail-info-box-profile img'>
                      <RenderImage image={apiRoute.apiAppBaseRoute + store.image} class_='img-fluid' />
                    </div>
                  ) : (
                    <div className='detail-info-box-profile'>
                      <RenderImage image={addImage} class_='img-fluid' />
                    </div>
                  )}

                  <div className='detail-info-box-title'>
                    <h5 className='sec-header'>{store.name}</h5>
                  </div>
                  <div className='detail-info-box-info'>
                    {store.address && (
                      <a>
                        <span>
                          <FasIcon icon='faMapMarkerAlt' />
                        </span>
                        <b>{store.address}</b>
                      </a>
                    )}

                    {store.email && (
                      <a>
                        <span>
                          <FarIcon icon='faEnvelopeOpen' />
                        </span>
                        <b>{store.email}</b>
                      </a>
                    )}
                    {store.createdAt && (
                      <a>
                        <span>
                          <FasIcon icon='faClock' />
                        </span>
                        Registered At: <b>{new Date(store.createdAt).toLocaleString()}</b>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ***Info Tab*** */}
        <section className='info-tab d-inline-block position-relative w-100 pt-0'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='info-tab-box'>
                  <div className='info-tab-box-tabular'>
                    <div className='btn-group radio-btns' id='status' data-toggle='buttons'>
                      <label className='btn btn-on active'>
                        <input
                          type='radio'
                          defaultValue={1}
                          name='multifeatured_module[module_id][status]'
                          defaultChecked='checked'
                        />
                        <FasIcon icon='faPhoneAlt' />
                      </label>
                      <label className='btn btn-off'>
                        <input type='radio' defaultValue={0} name='multifeatured_module[module_id][status]' />
                        <FarIcon icon='faComments' />
                      </label>
                    </div>
                  </div>
                  <div className='info-tab-box-detail'>
                    <p>{store.description}</p>
                  </div>
                  <section className='banner d-inline-block position-relative w-100'>
                    <div className='container-fluid'>
                      <div className='row'>
                        <div className='col-lg-5 col-md-6 mb-4 mb-lg-0'>
                          <div className='banner-box'>
                            <div className='banner-content bg3'>
                              <div className='banner-content-left'>
                                <h2 className='text-xl'>{data.total_claims}</h2>
                                <p className='text-lg'>Total Claims</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className='col-lg-7 col-md-6'
                          style={{
                            overflow: 'hidden',
                            width: '100%',
                            borderRadius: '10px',
                          }}
                        >
                          <div className='banner-box'>
                            {store && store.location && (
                              <GoogleMap lat={Typy(store, 'location.lat')} lng={Typy(store, 'location.long')} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withRouter(StoreView);
