import { ErrorMessage } from 'formik';
import React from 'react';

export function Select({ field, type, options, placeholder, ...props }) {
  return (
    <>
      <div className='dropdown'>
        <select
          name={field.name}
          id={field.name}
          className='form-control dropdown-toggle org-name'
          {...field}
          {...props}
        >
          <option value='' className='dropdown-item org'>
            {placeholder}
          </option>
          {(options || []).map((opt, i) => (
            <option value={opt.value} className='dropdown-item org' key={`${opt.value}_${i}`}>
              {opt.title}
            </option>
          ))}
        </select>
      </div>

      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}
