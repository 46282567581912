import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import Editor from '../Create/Editor';
import { ImageUpload } from '../Create/ImageUpload';
import { initialValues, validationSchema } from '../helper';
import './menuEdit.scss';

function MenuEdit(props) {
  const itemId = props.computedMatch.params.id;
  console.log(props.computedMatch.params.id);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialValues);

  const goToList = () => {
    props.history.push(appRoute.menu);
  };

  const handleForm = async (values, { setSubmitting, setErrors }) => {
    let sendData = new FormData();
    Object.keys(values).forEach(function (key) {
      sendData.append(key, values[key]);
    });
    try {
      const res = await axios.put(`menu/${itemId}`, sendData);
      notifySuccess(res.data.message);
      goToList();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  const getItemData = async () => {
    setLoading(true);

    try {
      const res = await axios.get(`${apiRoute.menu}/${itemId}`);
      let r = res.data.data;
      const { title, icon, description } = r.data;
      setData({
        title,
        icon,
        description,
      });
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Edit Menu</h5>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          {loading && <FsSpinner />}

          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={handleForm}
            enableReinitialize={true}
          >
            {({ values, isSubmitting, setFieldValue, setFieldTouched, setErrors }) => (
              <Form>
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-12 col-lg-10'>
                        <div className='add-form-basic-form'>
                          <ImageUpload
                            name='icon'
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            setErrors={setErrors}
                            values={values}
                            data={data}
                          />

                          <div className='form-group'>
                            <Field name='title' placeholder='Add Title' component={Input2} />
                          </div>

                          <div className='form-group'>
                            {values.description && (
                              <Editor
                                initialValue={values.description}
                                onChange={(value) => {
                                  setFieldValue('description', value);
                                }}
                              />
                            )}
                          </div>
                          <ErrorMessage name={'description'} component='div' className='form-field-error' />
                          <FormSubmitBtn text='Save Menu' isSubmitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default withRouter(MenuEdit);
