import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import { FarIcon } from 'components/helperComp/FontAwesomeIcon';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common, validationMsg as vm } from 'utils/constant';
import { getErrorObj, Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import * as Yup from 'yup';
import './user.scss';

const initialValues_ = {
  fullname: '',
  email: '',
  mobile_no: '',
  image: null,
};

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required(vm.fullname.required),
  email: Yup.string().email(vm.email.invalid).required(vm.email.required),
  mobile_no: Yup.string().required(vm.mobile_no.required),
  image: Yup.string().nullable(),
});

function EditProfile(props) {
  const [data, setData] = useState(initialValues_);
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const userDetail = useSelector((state) => Typy(state, 'auth.user', {}));

  const goToDashboard = () => {
    props.history.push(appRoute.dashboard);
  };

  const handleForm = async (values, { setSubmitting, setErrors }) => {
    let sendData = new FormData();

    Object.keys(values).forEach(function (key) {
      sendData.append(key, values[key]);
    });

    try {
      const res = await axios.put(apiRoute.userProfileUpdate, sendData);
      const { user } = res.data.data;
      notifySuccess(res.data.message);
      dispatch({
        type: 'SET_USER',
        payload: { user },
      });
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    let { image, fullname, email, mobile_no } = userDetail || {};
    setData({ image, fullname, email, mobile_no });
  }, [userDetail]);

  return (
    <>
      <div>
        {/* ***Header*** */}
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToDashboard} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Edit Profile</h5>
              </div>
            </div>
          </div>
        </header>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleForm}
          enableReinitialize={true}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className='ex-padding'>
                {/* ***Profile Status*** */}
                <section className='profile-status d-inline-block position-relative w-100'>
                  <div className='container-fluid'>
                    <div className='row align-items-center'>
                      <div className='col-6'>
                        <input
                          name='image'
                          type='file'
                          id='user-profile-image'
                          accept='image/x-png,image/gif,image/jpeg'
                          hidden
                          onChange={(event) => {
                            let file = event.currentTarget.files[0];
                            setFieldValue('image', file);
                            if (file) {
                              let reader = new FileReader();
                              reader.onload = (e) => {
                                setImage(e.target.result);
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                        />

                        {!values.image ? (
                          <>
                            <label htmlFor='user-profile-image'>
                              <div className='profile-status-img'>
                                <FarIcon icon='faImage' />
                              </div>
                            </label>
                          </>
                        ) : (
                          <label htmlFor='user-profile-image'>
                            <div className='profile-status-img img'>
                              <RenderImage
                                image={image || apiRoute.apiAppBaseRoute + values.image}
                                class_='img-fluid'
                              />
                            </div>
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                {/* ***add-form*** */}
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='add-form-basic-form'>
                          <h5 className='sec-header'>Basic Details</h5>

                          <div className='form-group'>
                            <Field name='fullname' label='Fullname' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field name='email' label='Email' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field name='mobile_no' label='Mobile Number' component={Input2} />
                          </div>

                          <FormSubmitBtn text='Save Now' isSubmitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default withRouter(EditProfile);
