import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import { SpinnerComp } from 'components/helperComp/Spinner';
import { ErrorMessage } from 'formik';
import React, { useState } from 'react';

export function LoginInput({ field, form, label, ...props }) {
  return (
    <>
      <div className='form-field'>
        <div className='form-field-icon'>
          <FasIcon icon='faEnvelopeOpen' />
        </div>
        <div className='form-field-body'>
          <input id={field.name} className='input-text js-input' {...field} {...props} />
          <label className='label' htmlFor={field.name}>
            {label}
          </label>
        </div>
      </div>
      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}

export function LoginPassword({ field, form, label, ...props }) {
  const [view, setView] = useState(false);
  return (
    <>
      <div className='form-field '>
        <div className='form-field-icon'>
          <FasIcon icon='faUnlockAlt' />
        </div>
        <div className='form-field-body'>
          <input
            id={field.name}
            className='input-text js-input'
            type={view ? 'text' : 'password'}
            {...field}
            {...props}
          />
          <label className='label' htmlFor={field.name}>
            {label}
          </label>
        </div>
        <div className='form-field-eye' onClick={() => setView(!view)}>
          {view ? <FarIcon icon='faEyeSlash' /> : <FarIcon icon='faEye' />}
        </div>
      </div>
      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}

export function AuthSubmitBtn({ text, icon, isSubmitting, ...props }) {
  return (
    <button className='submit-btn btn-filled' type='submit' disabled={isSubmitting} {...props}>
      {isSubmitting ? (
        <SpinnerComp
          style={{
            position: 'relative',
            margin: 'auto auto',
            top: 0,
            left: 0,
            height: '25px',
            width: '25px',
          }}
        />
      ) : (
        <>
          {text}
          {icon}
        </>
      )}
    </button>
  );
}
