import axios from 'axios';
import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common, validationMsg as vm } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import * as Yup from 'yup';
import AuthLayout from './AuthLayout';
import { AuthSubmitBtn, LoginInput } from './helperComp';
import './login.scss';

export const initialValues = {
  email: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email(vm.email.invalid).required(vm.email.required),
});

function ForgotPassword() {
  const history = useHistory();

  const handleForm = async (form_data, { setSubmitting, setErrors }) => {
    try {
      const res = await axios.post(apiRoute.forgotPassword, form_data);
      notifySuccess(res.data.message);
      history.push(appRoute.login);
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  return (
    <AuthLayout>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleForm}>
        {({ isSubmitting }) => (
          <Form className='contact-form'>
            <div className='form-body'>
              <Field name='email' label='Email' component={LoginInput} />
            </div>

            <div className='form-footer'>
              <Link to={appRoute.login}>
                <div className='forgot-link' style={{ background: 'none' }}>
                  Go to Login
                </div>
              </Link>

              <AuthSubmitBtn
                text='Reset Passoword'
                icon={<FasIcon icon='faArrowRight' />}
                isSubmitting={isSubmitting}
                style={{ width: 'auto', margin: 0, minWidth: '160px' }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
}

export default ForgotPassword;
