import { difference, reverse, uniqBy } from 'lodash';
import { validationMsg as vm } from 'utils/constant';
import * as Yup from 'yup';

Yup.addMethod(Yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    let unique_list = uniqBy(list, 'value');

    const isUnique = unique_list.length === list.length;

    if (isUnique) {
      return true;
    }

    let diffrence_array = difference(list, unique_list);
    let diffrence_value = diffrence_array[0]?.value || null;
    let index = list.length - 1 - reverse(list).findIndex((item) => item.value === diffrence_value);

    return this.createError({ path: `options[${index}].value`, message });
  });
});

export const initialValues = {
  name: '',
  description: '',
  video: null,
  questionsLimit: 1,
  passingScore: 1,
};

export const validationSchema = Yup.object({
  name: Yup.string().required(vm.name.required),
  description: Yup.string().required(vm.description.required),
  video: Yup.string().nullable().required(vm.video.required),
  questionsLimit: Yup.string().required(vm.questionsLimit.required),
  passingScore: Yup.string().required(vm.passingScore.required),
});

export const initialValuesQue_ = {
  title: '',
  options: [
    {
      value: '',
    },
    {
      value: '',
    },
  ],
  message: '',
  answer: '0',
};

export const validationSchemaQue = Yup.object({
  title: Yup.string().required(vm.title.required),
  options: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string().required(vm.options.value.required),
      })
    )
    .unique(vm.options.mustUnique)
    .required(),
  answer: Yup.string().required(vm.answer.required),
});
