import axios from 'axios';
import { PrimaryButton } from 'components/helperComp/Buttons';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { apiRoute, common } from 'utils/constant';
import { notifyAlert, notifySuccess } from 'utils/notify';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '5px',
  background: isDragging ? '#e9f9ff' : '#f9fdff',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightblue',
  padding: grid,
  width: '250px',
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '5px',
});

function MenuOrganize(props) {
  const { data, closeModal } = props;

  const [items, setItems] = useState(data);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items_ = reorder(items, result.source.index, result.destination.index);
    setItems(items_);
  };

  const handleSaveMenuOrganize = async () => {
    let formData = items.map((item, index) => ({ id: item._id, position: index + 1 }));
    try {
      const res = await axios.put(apiRoute.menuOrganize, formData);
      const r = res.data;
      notifySuccess(r.message);
      closeModal();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {items.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className='menu-box-text detail'
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <span>{item.title}</span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <hr />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <PrimaryButton text='Save' style={{ minWidth: '100px' }} onClick={handleSaveMenuOrganize} />
      </div>
    </>
  );
}

export default MenuOrganize;
