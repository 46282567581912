import React from 'react';

export function SpinnerComp(props) {
  return (
    <svg className='spinner' viewBox='0 0 50 50' {...props}>
      <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
    </svg>
  );
}

export function TableSpinner(props) {
  return (
    <div className='d-flex'>
      <SpinnerComp {...props} />
    </div>
  );
}

export function FsSpinner() {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        width: '82%',
        height: '80%',
        zIndex: 100,
        opacity: 0.5,
      }}
    >
      <SpinnerComp style={{ background: 'black', borderRadius: '100%', zIndex: 100 }} />
    </div>
  );
}
