import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common, validationMsg as vm } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import * as Yup from 'yup';

const initialValues = {
  iosVersion: '',
  androidVersion: '',
  forceUpdate: false,
  debugMode: false,
};

const validationSchema = Yup.object().shape({
  iosVersion: Yup.string().required(vm.setting.ios_version.required),
  androidVersion: Yup.string().required(vm.setting.android_version.required),
  forceUpdate: Yup.boolean().required(vm.setting.force_update.required),
  debugMode: Yup.boolean().required(vm.setting.debug_mode.required),
});

function Setting(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialValues);

  const goToDashboard = () => {
    props.history.push(appRoute.dashboard);
  };

  const handleForm = async (form_data, { setSubmitting, setErrors }) => {
    try {
      const res = await axios.post(apiRoute.setting, form_data);
      notifySuccess(res.data.message);
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  const getData = async (args) => {
    try {
      const res = await axios.get(apiRoute.setting);
      const r = res.data.data;

      const { iosVersion = '', androidVersion = '', forceUpdate = false, debugMode = false } = r.data;

      setData({
        iosVersion,
        androidVersion,
        forceUpdate,
        debugMode,
      });

      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        {/* ***Header*** */}
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToDashboard} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Settings</h5>
              </div>
            </div>
          </div>
        </header>
        <Formik
          enableReinitialize={true}
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleForm}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form>
              <div className='ex-padding'>
                {loading && <FsSpinner />}

                {/* ***add-form*** */}
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='add-form-basic-form'>
                          <div className='form-group'>
                            <label>IOS Version</label>
                            <Field name='iosVersion' placeholder='Enter IOS Version' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <label>Android Version</label>
                            <Field name='androidVersion' placeholder='Enter Android Version' component={Input2} />
                          </div>
                          <div className='profile-status-toggle'>
                            <label htmlFor='' className='mb-3'>
                              Force Update
                            </label>
                            <div className='toggle'>
                              <div
                                className='material-switch'
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  id='forceUpdate'
                                  name='forceUpdate'
                                  type='checkbox'
                                  checked={values.forceUpdate}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue('forceUpdate', true);
                                    } else {
                                      setFieldValue('forceUpdate', false);
                                    }
                                  }}
                                />
                                <label htmlFor='forceUpdate' className='label-default' />
                                {values.forceUpdate ? (
                                  <span className='status status-active'>Yes</span>
                                ) : (
                                  <span>No</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='profile-status-toggle mt-4'>
                            <label htmlFor='' className='mb-3'>
                              Debug Mode
                            </label>
                            <div className='toggle'>
                              <div
                                className='material-switch'
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              >
                                <input
                                  id='debugMode'
                                  name='debugMode'
                                  type='checkbox'
                                  checked={values.debugMode}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue('debugMode', true);
                                    } else {
                                      setFieldValue('debugMode', false);
                                    }
                                  }}
                                />
                                <label htmlFor='debugMode' className='label-default' />
                                {values.debugMode ? <span className='status status-active'>Yes</span> : <span>No</span>}
                              </div>
                            </div>
                          </div>

                          <button type='submit' className='btn-filled mx-auto' disabled={isSubmitting}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default withRouter(Setting);
