import { validationMsg as vm } from 'utils/constant';
import * as Yup from 'yup';

export const initialValues = {
  logo: null,
  mapMarker: null,
  fullname: '',
  name: '',
  email: '',
  mobile_no: '',
  address: '',
  country: '',
  postalcode: '',
  description: '',
  primaryColor: '',
  secondaryColor: '',
  status: false,
  password: '',
};

export const commonSchema = {
  logo: Yup.string().nullable().required(vm.logo.required),
  mapMarker: Yup.string().nullable().required(vm.mapMarker.required),
  fullname: Yup.string().required(vm.fullname.required),
  name: Yup.string().required(vm.name.required),
  email: Yup.string().email(vm.email.invalid).required(vm.email.required),
  mobile_no: Yup.string().required(vm.mobile_no.required),
  address: Yup.string().required(vm.address.required),
  country: Yup.string().required(vm.country.required),
  postalcode: Yup.string().required(vm.postalcode.required),
  description: Yup.string().required(vm.description.required),
  status: Yup.string().required(vm.status.required),
  password: Yup.string().min(6, vm.password.min6char).required(vm.password.required),
};

export const validationSchema = Yup.object().shape({
  ...commonSchema,
});

export const validationSchemaEdit = Yup.object().shape({
  ...commonSchema,
  password: Yup.string().min(6, vm.password.min6char),
});

export const validationSchemaRegQuestion = Yup.object().shape({
  title: Yup.string().required(vm.title.required),
  hasTextField: Yup.boolean(),
  options: Yup.array().of(Yup.string().required(vm.options.required)),
});

export const initialValuesQue = {
  title: '',
  hasTextField: false,
  options: ['', ''],
};

export const exportOrgHeaders = [
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email' },
  { key: 'mobile_no', label: 'Mobile number' },
  { key: 'address', label: 'Address' },
  { key: 'country', label: 'Country' },
  { key: 'postalcode', label: 'Postcode' },
  { key: 'description', label: 'Description' },
  { key: 'createdAt', label: 'Createdat' },
  { key: 'status', label: 'Status' },
];
