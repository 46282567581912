import { ErrorMessage, useField } from 'formik';
import React from 'react';

export function TextArea2({ field, type, ...props }) {
  return (
    <>
      <textarea type='textarea' className='form-control' {...field} {...props} style={{ height: '125px' }} />

      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}

function TextArea({ type, style = {}, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <textarea type='textarea' className='form-control' {...field} {...props} style={{ height: '125px', ...style }} />
      {meta.touched && meta.error ? <div className='form-field-error'>{meta.error}</div> : null}
    </>
  );
}

export default TextArea;
