import axios from 'axios';
import RenderImage from 'components/helperComp/RenderImage';
import { SpinnerComp } from 'components/helperComp/Spinner';
import Cookie from 'js-cookie';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import { setupAxios } from 'utils/setupAxios';
import edit_profile_img from '../../assets/images/icon/edit profile.png';
import setting_img from '../../assets/images/icon/setting.png';
import user_profile_img from '../../assets/images/user profile.png';
import './userInfo.scss';

function UserInfo({ user, role }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);
  const [isLogoutLoader, setIsLogoutLoader] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLogoutLoader(true);
      const res = await axios.post(apiRoute.logout);
      notifySuccess(res.data.message);
      dispatch({
        type: 'LOGOUT',
      });
      Cookie.remove(common.authTokenKey);
      setupAxios();
      return await history.push('/login');
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
      setIsLogoutLoader(false);
    }
  };

  return (
    <div className='user-info'>
      <div className='user-info-box'>
        <div className='user-info-box-edit-user'>
          {user?.image ? (
            <RenderImage
              image={apiRoute.apiAppBaseRoute + user.image}
              class_='img-fluid'
              style={{
                borderRadius: '100%',
                border: '3px solid #a7e3f9',
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <RenderImage image={user_profile_img} class_='img-fluid' />
          )}
          <Link to={appRoute.editProfile}>
            <div className='edit-link'>
              <RenderImage image={edit_profile_img} class_='img-fluid' />
            </div>
          </Link>
        </div>
        <div className='user-info-box-textbox'>
          <div className='user-info-box-textbox-text'>
            <p>{user ? user.fullname : 'Welcome'}</p>
            <span>{user && user.mobile_no}</span>
          </div>
          <div className='user-info-box-textbox-icon'>
            <div className='dropbtn' onClick={() => setShowDropdown(!showDropdown)}>
              <RenderImage image={setting_img} class_='img-fluid' />
            </div>

            <div className='dropdown-content' style={showDropdown ? { display: 'block' } : { display: 'none' }}>
              <Link to={appRoute.viewProfile}>
                <div className='link'>View Profile</div>
              </Link>
              <hr />
              <Link to={appRoute.changePassword}>
                <div className='link'>Change Password</div>
              </Link>
              <hr />

              {role === common.orgRoleKey && (
                <>
                  <Link to={appRoute.setting}>
                    <div className='link'>Setting</div>
                  </Link>
                  <hr />
                </>
              )}
              <div className='link' onClick={handleLogout}>
                {isLogoutLoader ? (
                  <SpinnerComp
                    style={{
                      position: 'relative',
                      margin: 'auto auto',
                      top: 0,
                      left: 0,
                      height: '25px',
                      width: '25px',
                    }}
                  />
                ) : (
                  'Logout'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: Typy(state, 'auth.user', {}),
  role: Typy(state, 'auth.role', common.orgRoleKey),
});

export default connect(mapStateToProps)(UserInfo);
