import axios from 'axios';
import { FsSpinner } from 'components/helperComp/Spinner';
import React, { Suspense, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import './assets/css/main.scss';
import './assets/css/responsive.scss';
import { history, store } from './redux/store';
import AppRoutes from './routes/AppRoutes';
import { apiRoute } from './utils/constant';
import { setupAxios, setupAxiosCommon } from './utils/setupAxios';

export const getInitialDetails = async () => {
  // run only one time when page refresh
  try {
    const res = await axios.get(apiRoute.getInitialdata);
    const { user, role, organization } = res.data.data;

    setupAxios(null, role);

    store.dispatch({
      type: 'SET_USER',
      payload: { user },
    });
    store.dispatch({
      type: 'SET_ROLE',
      payload: { role },
    });
    store.dispatch({
      type: 'SET_ORG',
      payload: { organization },
    });
  } catch (error) {
    // notifyAlert(common.somethingWrongMsg);
  }
};

function App() {
  setupAxios();
  setupAxiosCommon();

  useEffect(() => {
    getInitialDetails();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router history={history} basename={process.env.REACT_APP_PUBLIC_URL}>
          <Suspense fallback={<FsSpinner />}>
            <ReactNotification />
            <AppRoutes />
          </Suspense>
        </Router>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
