import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { common } from 'utils/constant';
import {
  customerFilterInitialValues,
  customerFilterValidationSchema,
  filterBlocked,
  FilterInput,
  FilterSelect,
  filterStatus,
  filterUnderage,
  filterOverage,
  objToQueryString,
} from './helper';

function CustomerFilter(props) {
  const { orgs, setFilter } = props;
  const role = useSelector((state) => state?.auth?.role || common.orgRoleKey);

  return (
    <Formik
      initialValues={customerFilterInitialValues}
      validationSchema={customerFilterValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setFilter(objToQueryString(values));
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <Form>
          <div className='d-flex'>
            <Field name='search' placeholder='Name' component={FilterInput} />
            {role === common.adminRoleKey && (
              <Field name='organization' placeholder='Organization' options={orgs} component={FilterSelect} />
            )}
            <Field name='underage' placeholder='Underage' options={filterUnderage} component={FilterSelect} />
            <Field name='overage' placeholder='Overage' options={filterOverage} component={FilterSelect} />
            <Field name='blocked' placeholder='Blocked' options={filterBlocked} component={FilterSelect} />
            <Field name='status' placeholder='Status' options={filterStatus} component={FilterSelect} />

            <div style={{ height: '50px', alignSelf: 'flex-end' }}>
              <FormSubmitBtn text='Search' isSubmitting={isSubmitting} />
            </div>

            <div className='ml-2' style={{ height: '50px', alignSelf: 'flex-end' }}>
              <button className='btn-filled mx-auto' onClick={resetForm}>
                Clear Filter
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CustomerFilter;
