import searchIcon from 'assets/images/icon/search.png';
import axios from 'axios';
import Table from 'components/core/Table';
import RenderImage from 'components/helperComp/RenderImage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert } from 'utils/notify';
import { commonColumn, moduleName } from './helper';

function Notification() {
  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const column =
    role === common.adminRoleKey
      ? [
          {
            title: 'Org. Name',
            key: 'organization.name',
          },
          ...commonColumn,
        ]
      : [...commonColumn];

  const getData = async (args) => {
    setLoading(true);
    const { page, limit, search: search_ } = args || {};
    try {
      const res = await axios.get(
        `${apiRoute.notification}?page=${page || pagination.page}&limit=${limit || pagination.limit}&search=${
          search_ ?? search
        }`
      );

      const r = res?.data?.data;
      const { docs, ...pagination_ } = r.notifications;

      setData(docs);
      setPagination(pagination_);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <h5>
                  {moduleName} <span>({(data || []).length})</span>
                </h5>
                <div className='search'>
                  <div className='search-input-div' id='searchInputDiv'>
                    <input
                      type='search'
                      className='search-input'
                      placeholder={`Search ${moduleName}`}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        getData({ search: e.target.value });
                      }}
                    />
                  </div>
                  <span className='search-icon' id='searchIcon'>
                    <RenderImage image={searchIcon} class_='img-fluid' />
                  </span>
                </div>
              </div>
              <div className='header-right'>
                <Link to={'/notification/send'}>
                  <button type='button' className='btn-filled'>
                    <span>Send {moduleName}</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          <Table
            columns={column}
            dataSource={data}
            pagination={pagination}
            onPageChange={(page) => getData({ page })}
            onPerPageChange={(limit) => getData({ limit })}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default Notification;
