export const appRoute = {
  homepage: '/',

  login: '/login',
  forgotPassword: '/forgot-password',
  newPasswordRoute: '/new-password',
  changePassword: '/change-password',
  newPassword: '/new-password/:id',
  editProfile: '/edit-profile',
  viewProfile: '/view-profile',
  setting: '/setting',

  dashboard: '/dashboard',

  organization: '/organization',
  organizationView: '/organization/:id/view',
  organizationEdit: '/organization/:id/edit',
  organizationCreate: '/organization/create',
  organizationRegQuestions: '/organization/:id/add-register-questions',

  store: '/store',
  storeView: '/store/:id/view',
  storeEdit: '/store/:id/edit',
  storeCreate: '/store/create',

  customer: '/customer',
  customerView: '/customer/:id/view',

  menu: '/menu',
  menuView: '/menu/:id/view',
  menuEdit: '/menu/:id/Edit',
  menuCreate: '/menu/create',

  quizbank: '/quizbank',
  quizbankView: '/quizbank/:id/view',
  quizbankEdit: '/quizbank/:id/Edit',
  quizbankCreate: '/quizbank/create',

  notification: '/notification',
  notificationSend: '/notification/send',
};

const apiBaseRoute = process.env.REACT_APP_API_APP_URL || 'http://127.0.0.1:5000';

export const apiRoute = {
  apiAppBaseRoute: `${apiBaseRoute}/`,
  apiAdminRoute: process.env.REACT_APP_API_ADMIN_URL || `${apiBaseRoute}/api/v1/admin`,
  apiOrgRoute: process.env.REACT_APP_API_ORG_URL || `${apiBaseRoute}/api/v1/org`,
  getInitialdata: '/get-initials',
  login: '/auth/login',
  logout: '/auth/logout',
  newPassword: '/auth/new-password',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  userProfileUpdate: '/user/update-profile',
  dashboard: '/dashboard',
  organization: '/organization',
  store: '/store',
  customer: '/customer',
  allowUnderage: '/customer/allow-underage',
  quiz: '/quiz',
  menu: '/menu',
  menuOrganize: '/menu/organize',
  setting: '/setting',
  regQuestion: '/register-question',
  notification: '/notification',
  notificationSend: '/notification/send',
};

export const common = {
  authTokenKey: '_token',
  roleKey: '_role',
  orgRoleKey: 'organization',
  adminRoleKey: 'admin',
  somethingWrongMsg: 'Something went wrong !',
  internalServerErrorMsg: 'Internal server error !',
  unauthorizedMsg: 'You are not authorized !',
  notRegisteredMsg: 'You are not registered with us',
};

// valaidation helper functions
function fieldRequired(fieldName) {
  return `${fieldName} is Required`;
}

function fieldMustValid(fieldName) {
  return `${fieldName} must be valid`;
}

function fieldMustUnique(fieldName) {
  return `${fieldName} must be unique`;
}

function fieldMustBeetween(field, min, max) {
  return `${field} must between ${min} and ${max}`;
}

export const validationMsg = {
  email: {
    required: fieldRequired('Email'),
    invalid: fieldMustValid('Email'),
  },
  password: {
    required: fieldRequired('Password'),
    invalid: fieldMustValid('Password'),
    min6char: 'Minimum 6 charater is Required',
    shouldMatch: 'Password should match',
  },
  old_password: {
    required: fieldRequired('Old Password'),
  },
  new_password: {
    required: fieldRequired('New Password'),
  },
  password_confirm: {
    required: fieldRequired('Password Confirm'),
  },
  logo: {
    required: fieldRequired('Logo'),
  },
  mapMarker: {
    required: fieldRequired('Mapmarker'),
  },
  fullname: {
    required: fieldRequired('Fullname'),
  },
  name: {
    required: fieldRequired('Name'),
  },
  mobile_no: {
    required: fieldRequired('Mobile Number'),
  },
  address: {
    required: fieldRequired('Address'),
  },
  country: {
    required: fieldRequired('Country'),
  },
  postalcode: {
    required: fieldRequired('Postalcode'),
  },
  description: {
    required: fieldRequired('Description'),
  },
  status: {
    required: fieldRequired('Status'),
  },
  image: {
    required: fieldRequired('Image'),
  },
  organization: {
    required: fieldRequired('Organization'),
  },
  video: {
    required: fieldRequired('Video'),
  },
  questionsLimit: {
    required: fieldRequired('Questionslimit'),
  },
  passingScore: {
    required: fieldRequired('Passingscore'),
  },
  title: {
    required: fieldRequired('Title'),
  },
  answer: {
    required: fieldRequired('Answer'),
  },
  options: {
    required: fieldRequired('Options'),
    invalid: fieldMustValid('Options'),
    value: {
      required: fieldRequired("Option's Value"),
    },
    mustUnique: fieldMustUnique('Options'),
  },
  location: {
    lat: {
      required: fieldRequired('Latitude'),
      beetween: fieldMustBeetween('Latitude', -90, 90),
    },
    long: {
      required: fieldRequired('Longitude'),
      beetween: fieldMustBeetween('Latitude', -180, 180),
    },
  },
  icon: {
    required: fieldRequired('Icon'),
  },
  setting: {
    ios_version: {
      required: fieldRequired('Ios version'),
    },
    android_version: {
      required: fieldRequired('Android version'),
    },
    force_update: {
      required: fieldRequired('Force update'),
    },
    debug_mode: {
      required: fieldRequired('Debug mode'),
    },
  },
  schedule: {
    required: fieldRequired('Schedule Time'),
  },
};
