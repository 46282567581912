import Layout from 'components/Layout';
import Cookies from 'js-cookie';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { appRoute, common } from '../utils/constant';

// PR = Protected Routes
export default function PR(props) {
  const Component = props.component;
  const cookie_token = Cookies.get(common.authTokenKey);
  const role = Cookies.get(common.roleKey);

  return cookie_token && role ? (
    <Layout>
      <Component {...props} />
    </Layout>
  ) : (
    <Redirect to={{ pathname: appRoute.login }} />
  );
}
