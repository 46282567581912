import typy from 'typy';

export function Typy(obj, key, defaultValue) {
  let val = typy(obj, key).safeObject;
  return val === undefined ? defaultValue : val;
}

export function getErrorObj(errors) {
  return errors.reduce((e, ele) => {
    if (ele.param.includes('.')) {
      let arr = ele.param.split('.');
      e[arr[0]] = {
        [arr[1]]: ele.msg,
      };
    } else {
      e[ele.param] = ele.msg;
    }
    return e;
  }, {});
}

export function getValueFromDotIndex(o, s) {
  try {
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  } catch (e) {
    return '';
  }
}

export function renderCellData(record, column) {
  let ret_val = null;
  if (column.render) {
    ret_val = column.render(record, column);
  } else {
    if (column.key.includes('.')) {
      ret_val = getValueFromDotIndex(record, column.key);
    } else {
      ret_val = record[column.key];
    }
  }
  if (column.isDate && column.isDate === true) {
    return new Date(ret_val).toLocaleDateString();
  }
  return ret_val;
}
