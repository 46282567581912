import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { apiRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert } from 'utils/notify';

function Banner({ bgClass, count, title }) {
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 mb-4 mb-xl-0'>
      <div className='banner-box'>
        <div className={`banner-content ${bgClass}`}>
          <div className='banner-content-left'>
            <h2 className='text-xl'>{count}</h2>
            <p className='text-lg'>{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function Dashboard(props) {
  const { role } = props;
  const [data, setData] = useState({ customer: 0, organization: 0, store: 0, collection: 0 });

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(apiRoute.dashboard);
        const { customer = 0, organization = 0, store = 0, collection = 0 } = res.data.data;
        setData({ customer, organization, store, collection });
      } catch (err) {
        notifyAlert(common.somethingWrongMsg);
      }
    };

    getData();
  }, []);

  return (
    <>
      {/* ***Header*** */}
      <header className='header' id='header'>
        <div className='container-fluid'>
          <div className='header-content'>
            <div className='header-left'>
              <h5>My Dashboard</h5>
            </div>
            <div className='header-right' />
          </div>
        </div>
      </header>
      <div className='ex-padding'>
        {/* ***Banner*** */}
        <section className='banner d-inline-block position-relative w-100'>
          <div className='container-fluid'>
            <div className='row'>
              {role === common.adminRoleKey && (
                <Banner bgClass='bg1' count={data.organization} title='Total Organization' />
              )}
              <Banner bgClass='bg2' count={data.store} title='Total Store' />
              <Banner bgClass='bg3' count={data.customer} title='Total Customer' />
              <Banner bgClass='bg3' count={data.collection} title='Total Collection' />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mstp = (state) => ({
  role: Typy(state, 'auth.role', common.orgRoleKey),
});

export default connect(mstp)(Dashboard);
