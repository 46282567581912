const INITIAL_STATE = {
  user: null,
  role: null,
  organization: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case 'SET_USER':
      return {
        ...state,
        user: payload.user,
      };
    case 'SET_ROLE':
      return {
        ...state,
        role: payload.role,
      };
    case 'SET_ORG':
      return {
        ...state,
        organization: payload.organization,
      };

    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return state;
  }
}
