import searchIcon from 'assets/images/icon/search.png';
import axios from 'axios';
import Table from 'components/core/Table';
import { PrimaryButton } from 'components/helperComp/Buttons';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiRoute, common } from 'utils/constant';
import { notifyAlert, notifySuccess } from 'utils/notify';
import { downloadCSV } from '../../utils/exportCsv';
import { exportOrgHeaders } from './helper';
import './organizationDetail.scss';
import { Prints } from './Prints';

function Organization() {
  const [qrModal, setQrModal] = useState(false);
  const [qrModalData, setQrModalData] = useState(null);

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const handleQrModal = (status) => {
    if (!status) {
      setQrModalData(null);
    }
    setQrModal(status);
  };

  const column = [
    {
      title: 'Org. Name',
      key: 'name',
      render: (record) => <strong>{record.name}</strong>,
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Address',
      key: 'address',
    },
    {
      title: 'No. of Store',
      key: 'store',
    },
    {
      title: 'No. of Users',
      key: 'customer',
    },
    {
      title: 'Status',
      key: '',
      className: 'toggle',
      render: (record) => (
        <div className='material-switch'>
          <input
            key={`org_status_${record._id}`}
            id={`org_status_${record._id}`}
            type='checkbox'
            defaultChecked={record.status}
            onChange={(e) => {
              changeOrgStatus(record._id, e.target.checked);
            }}
          />
          <label htmlFor={`org_status_${record._id}`} className='label-default' />
        </div>
      ),
    },
    {
      title: 'Action',
      key: '',
      className: 'action',
      render: (record) => (
        <>
          <Link to={`organization/${record._id}/view`}>
            <div className='view'>
              <FarIcon icon='faEye' />
            </div>
          </Link>
          <Link to={`organization/${record._id}/edit`}>
            <div className='edit mr-3'>
              <FarIcon icon='faEdit' />
            </div>
          </Link>
          <Link to={`organization/${record._id}/add-register-questions`}>
            <div className='edit mr-3'>
              <FarIcon icon='faQuestionCircle' />
            </div>
          </Link>
          {record.storeDetail?.length > 0 && (
            <div style={{ cursor: 'pointer' }}>
              <div
                onClick={() => {
                  setQrModal(true);
                  setQrModalData(record);
                }}
              >
                <FasIcon icon='faQrcode' />
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const getData = async (args) => {
    setLoading(true);
    const { page, limit, search: search_ } = args || {};

    try {
      const res = await axios.get(
        `${apiRoute.organization}?page=${page || pagination.page}&limit=${limit || pagination.limit}&search=${
          search_ ?? search
        }`
      );
      const r = res.data.data;
      const { docs, ...pagination_ } = r;

      setData(docs || []);
      pagination_ && setPagination(pagination_);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeOrgStatus = async (id, status) => {
    try {
      const res = await axios.put(`${apiRoute.organization}/${id}/status-update?status=${status}`);
      notifySuccess(res.data.message);
      console.log(id);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleExportData = async () => {
    try {
      const res = await axios.get(`${apiRoute.organization}/all?search=${search}`);
      const r = res.data.data;
      downloadCSV(r, exportOrgHeaders, 'Organizations');
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <h5>
                  Organization <span>({(data || []).length})</span>
                </h5>
                <div className='search'>
                  <div className='search-input-div' id='searchInputDiv'>
                    <input
                      type='search'
                      className='search-input'
                      placeholder='Search Organization'
                      onChange={(e) => {
                        setSearch(e.target.value);
                        getData({ search: e.target.value });
                      }}
                    />
                  </div>
                  <span className='search-icon' id='searchIcon'>
                    <RenderImage image={searchIcon} class_='img-fluid' />
                  </span>
                </div>
              </div>
              <div className='header-right'>
                <PrimaryButton text='Export Data' onClick={handleExportData} />

                <Link to={'/organization/create'}>
                  <button type='button' className='btn-filled'>
                    + <span>Add Org.</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          <Table
            columns={column}
            dataSource={data}
            pagination={pagination}
            onPageChange={(page) => getData({ page })}
            onPerPageChange={(limit) => getData({ limit })}
            loading={loading}
          />
        </div>

        {qrModal && qrModalData && (
          <>
            <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
              <div
                className='modal-dialog modal-dialog-centered'
                role='document'
                style={{
                  maxWidth: '1120px',
                }}
              >
                <div className='modal-content' style={{ paddingBottom: '1rem' }}>
                  <div
                    className='modal-header'
                    style={{
                      paddingBottom: '10px',
                    }}
                  >
                    <p className='modal-title sec-header'>{qrModalData.name} QR Codes</p>
                    <button type='button' className='close' onClick={() => handleQrModal(false)}>
                      <span aria-hidden='true'>×</span>
                    </button>
                  </div>

                  <Prints stores={qrModalData.storeDetail} org={qrModalData} hideModal={() => handleQrModal(false)} />
                </div>
              </div>
            </div>
            <div className='modal-backdrop fade show'></div>
          </>
        )}
      </div>
    </>
  );
}

export default Organization;
