import axios from 'axios';
import Cookie from 'js-cookie';
import { history, store } from '../redux/store';
import { apiRoute, appRoute, common } from './constant';
import { notifyAlert } from './notify';

export function setupAxiosCommon() {
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Accept'] = 'application/json';

  axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (axios.isCancel()) {
        return false;
      }
      const _status = error.response && error.response.status;
      switch (_status) {
        case 400:
          // wrong credential
          notifyAlert(error.response.data.message);
          break;
        case 401:
          // not authenticated
          axios.defaults.headers.common['Authorization'] = null;
          Cookie.remove(common.authTokenKey);
          Cookie.remove(common.roleKey);

          if ([appRoute.login, appRoute.newPasswordRoute].includes('/' + history.location.pathname.split('/')[1])) {
            break;
          }

          store.dispatch({
            type: 'LOGOUT',
          });
          if (history.location.pathname !== appRoute.homepage) {
            history.push(appRoute.login);
          }
          notifyAlert(common.unauthorizedMsg);
          break;
        case 403:
          // not access to api
          history.push(appRoute.dashboard);
          break;
        case 404:
          // not found
          notifyAlert(error.response.data.message);
          break;
        case 500:
          // internal server error
          notifyAlert(common.internalServerErrorMsg);
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );
}

export function setupAxios(token = null, role) {
  let role_ = role || Cookie.get(common.roleKey);

  let authToken = token || Cookie.get(common.authTokenKey);

  if (role_ === common.orgRoleKey && authToken) {
    axios.defaults.baseURL = apiRoute.apiOrgRoute;
  } else {
    axios.defaults.baseURL = apiRoute.apiAdminRoute;
  }

  axios.defaults.headers.common['Authorization'] = authToken || null;
}
