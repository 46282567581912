import 'assets/css/organization.scss';
import axios from 'axios';
import { PrimaryButton } from 'components/helperComp/Buttons';
import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import { FsSpinner } from 'components/helperComp/Spinner';
import { Header, Modal, NoData } from 'components/helperComp/Template';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { notifyAlert } from 'utils/notify';
import './menu.scss';
import MenuOrganize from './MenuOrganize';

function MenuIndex(props) {
  const { role } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [menuOrganizeModal, setMenuOrganizeModal] = useState(false);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await axios.get(apiRoute.menu);
      const r = res.data.data;
      setData(r.data || []);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header title='Menu'>
        {role !== common.adminRoleKey && <PrimaryButton text='Organize' onClick={() => setMenuOrganizeModal(true)} />}

        <Link to={appRoute.menuCreate}>
          <PrimaryButton text='+ Add Menu' />
        </Link>
      </Header>

      <div className='ex-padding'>
        <section className='menu d-inline-block opsition-relative w-100'>
          <div className='container-fluid'>
            <div className='row'>
              {loading && <FsSpinner />}
              {data.length < 1 && !loading ? (
                <NoData />
              ) : (
                data.map((menu, index) => (
                  <div
                    className='col-xl-3 col-lg-6 col-md-6 col-sm-6'
                    key={index}
                    style={role === common.adminRoleKey ? { marginBottom: '3rem' } : { marginBottom: '1rem' }}
                  >
                    <div className='menu-box'>
                      <div className='custom-control custom-checkbox image-checkbox menu-box-img'>
                        <div
                          className={`menu-box-top d-flex justify-content-between align-items-center ${
                            role === common.orgRoleKey ? 'remove-padding' : 'add-padding'
                          }`}
                        >
                          <div className='d-flex'>
                            <div className='menu-box-text'>
                              {menu.icon ? (
                                <>
                                  {/* {imageExists(menu.icon) ? (
                                    <RenderImage image={menu.icon} />
                                  ) : (
                                    <RenderImage image={defaultImage} />
                                  )} */}
                                </>
                              ) : (
                                <FasIcon className='menu-icon' icon='faImage' />
                              )}
                            </div>
                            <div className='menu-box-text detail'>
                              <span>{menu.title}:</span>
                              {/* <p>{menu.description}</p> */}
                            </div>
                          </div>
                          {role === common.orgRoleKey && (
                            <div className='menu-box-profiles-right'>
                              <Link to={`/menu/${menu._id}/view`}>
                                <button type='button' className='btn-bordered font-weight-bold'>
                                  View
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>

                      {role === common.adminRoleKey && (
                        <div className='menu-box-profiles' style={{ zIndex: 2 }}>
                          <div className='menu-box-profiles-left'>
                            <ul>
                              <li
                                style={{
                                  width: '150px',
                                  borderRadius: '10px',
                                  padding: '0 10px',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {menu?.organization?.name}
                              </li>

                              {/* <li style={{ background: 'none' }}>
                                <img src={menu.organization?.logo} className='img-fluid' alt='' />
                              </li> */}
                            </ul>
                          </div>
                          <div className='menu-box-profiles-right'>
                            <Link to={`/menu/${menu._id}/view`}>
                              <button type='button' className='btn-bordered font-weight-bold'>
                                View
                              </button>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </div>

      {menuOrganizeModal && role !== common.adminRoleKey && (
        <Modal heading='Organize Menu' closeModal={() => setMenuOrganizeModal(false)}>
          <div className='d-flex justify-content-center pt-2'>Drag and Drop to organize menu</div>
          <hr />
          <MenuOrganize
            data={data}
            closeModal={() => {
              setMenuOrganizeModal(false);
              getData();
            }}
          />
        </Modal>
      )}
    </>
  );
}

const mstp = (state) => ({ role: state?.auth?.role || common.orgRoleKey });

export default connect(mstp)(MenuIndex);
