import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import { TableSpinner } from 'components/helperComp/Spinner';
import React from 'react';
import { renderCellData } from 'utils/helper';
import Pagination from './Pagination';

function Table(props) {
  const { columns = [], dataSource = [], pagination, onPageChange, onPerPageChange, loading } = props;

  // hasNextPage: false
  // hasPrevPage: false
  // limit: 10
  // nextPage: null
  // prevPage: null
  // page: 1
  // pagingCounter: 1
  // totalDocs: 2
  // totalPages: 1

  return (
    <div className='main-table d-inline-block position-relative w-100'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className='table-responsive'>
              <table className='table'>
                <thead className='col-blue'>
                  <tr>
                    <th>No.</th>
                    {columns.map((col, index) => (
                      <th key={`column_${index}`}>{col.title}</th>
                    ))}
                  </tr>
                </thead>
                {dataSource.length > 0 && (
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {dataSource.map((data, rowIndex) => (
                      <tr key={`row_${rowIndex}`} className={rowIndex % 2 === 0 ? 'col-exlight' : 'col-lightBlue'}>
                        <td>{pagination.page * pagination.limit + rowIndex - pagination.limit + 1}</td>
                        {columns.map((col, colIndex) => (
                          <td
                            key={`cell_${rowIndex}_${colIndex}`}
                            className={col.className || ''}
                            style={{ maxWidth: '500px' }}
                          >
                            {renderCellData(data, col)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      {loading && <TableSpinner />}

      {dataSource.length <= 0 && !loading && (
        <div
          className='d-flex'
          style={{
            minHeight: '150px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div className='table-data-not-found'>
            <FasIcon icon='faExclamationCircle' className='h5 text-secondary' />
            <span className='ml-3 h5 text-secondary'>No Data Found</span>
          </div>
        </div>
      )}

      {pagination && <Pagination data={pagination} onPageChange={onPageChange} onPerPageChange={onPerPageChange} />}
    </div>
  );
}

export default Table;
