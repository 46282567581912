import addImage from 'assets/images/addImage.png';
import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import { Select } from 'components/core/Form/Select';
import { TextArea2 } from 'components/core/Form/TextArea';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj, Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import { initialValues, validationSchema, validationSchema_admin } from './helper';

function StoreEdit(props) {
  const itemId = props.computedMatch.params.id;

  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));

  const [data, setData] = useState(initialValues);
  const [image, setImage] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);

  const goToList = () => {
    props.history.push(appRoute.store);
  };

  const handleForm = async (values, { setSubmitting, setErrors }) => {
    values.location = JSON.stringify(values.location);
    let sendData = new FormData();
    Object.keys(values).forEach(function (key) {
      sendData.append(key, values[key]);
    });
    try {
      const res = await axios.put(`/store/${itemId}`, sendData);
      notifySuccess(res.data.message);
      goToList();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  const getOrgs = async () => {
    try {
      const res = await axios.get(apiRoute.organization + '/all');
      const orgs = res.data.data;

      setOrgs(orgs.map((o) => ({ title: o.name, value: o._id })));
    } catch (error) {}
  };

  const getItemData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${apiRoute.store}/${itemId}`);
      let r = res.data.data;
      const {
        image,
        name,
        email,
        mobile_no,
        address,
        country,
        postalcode,
        description,
        location,
        organization,
        status,
      } = r.data;

      setData({
        image,
        name,
        email,
        mobile_no,
        address,
        country,
        postalcode,
        description,
        // location,
        lat: location.lat,
        long: location.long,
        organization,
        status,
      });
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getItemData();
    role === common.adminRoleKey && getOrgs();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Edit Store</h5>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          {loading && <FsSpinner />}

          <Formik
            initialValues={data}
            validationSchema={role === common.adminRoleKey ? validationSchema_admin : validationSchema}
            onSubmit={handleForm}
            enableReinitialize={true}
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <Form>
                <section className='profile-status d-inline-block position-relative w-100'>
                  <div className='container-fluid'>
                    <div className='row align-items-center'>
                      <div className='col-6'>
                        <input
                          name='image'
                          type='file'
                          id='user-profile-image'
                          accept='image/x-png,image/gif,image/jpeg'
                          hidden
                          onChange={(event) => {
                            let file = event.currentTarget.files[0];
                            setFieldValue('image', file);
                            if (file) {
                              let reader = new FileReader();
                              reader.onload = (e) => {
                                setImage(e.target.result);
                              };
                              reader.readAsDataURL(file);
                            }
                          }}
                        />

                        {!values.image ? (
                          <label htmlFor='user-profile-image'>
                            <div className='profile-status-img'>
                              <RenderImage image={addImage} class_='img-fluid' />
                            </div>
                          </label>
                        ) : (
                          <label htmlFor='user-profile-image'>
                            <div className='profile-status-img img'>
                              <RenderImage
                                image={image || apiRoute.apiAppBaseRoute + values.image}
                                class_='img-fluid'
                              />
                            </div>
                          </label>
                        )}
                        <ErrorMessage name={'image'} component='div' className='form-field-error' />
                      </div>
                      <div className='col-6'>
                        <div className='profile-status-toggle'>
                          <div className='title'>
                            <p>Status :</p>
                          </div>
                          <div className='toggle'>
                            <div className='material-switch'>
                              <input
                                id='status'
                                name='status'
                                type='checkbox'
                                checked={values.status}
                                onChange={(e) => {
                                  if (e.target.checked === true) {
                                    setFieldValue('status', true);
                                  } else {
                                    setFieldValue('status', false);
                                  }
                                }}
                              />
                              <label htmlFor='status' className='label-default' />
                            </div>
                          </div>
                          {values.status ? <span className='status status-active'>Active</span> : <span>Inactive</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* ***add-form*** */}
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='add-form-basic-form'>
                          <h5 className='sec-header'>Basic Details</h5>
                          {role === common.adminRoleKey && (
                            <div className='form-group'>
                              <Field
                                name='organization'
                                placeholder='Select an Organization'
                                options={orgs}
                                component={Select}
                              />
                            </div>
                          )}
                          <div className='form-group'>
                            <Field name='name' placeholder='Store name' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field type='email' name='email' placeholder='Email address' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field type='tel' name='mobile_no' placeholder='Mobile number' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field name='address' placeholder='Store address' component={Input2} />
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <Field name='country' placeholder='Country' component={Input2} />
                            </div>
                            <div className='form-group col-md-6'>
                              <Field name='postalcode' placeholder='Post Code' component={Input2} />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='form-group col-md-6'>
                              <Field type='number' name='lat' placeholder='Store Latitude' component={Input2} />
                            </div>
                            <div className='form-group col-md-6'>
                              <Field type='number' name='long' placeholder='Store Longitude' component={Input2} />
                            </div>
                          </div>
                          <div className='form-group'>
                            <Field name='description' placeholder='About Store' component={TextArea2} />
                          </div>

                          <FormSubmitBtn isSubmitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default withRouter(StoreEdit);
