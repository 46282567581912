import customerProfile from 'assets/images/customerProfile.png';
import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { FarIcon, FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { notifyAlert, notifySuccess } from 'utils/notify';

function getAge(date) {
  let ageMonth = moment().diff(date, 'months');
  let ageYear = moment().diff(date, 'years');

  let extraMonth = ageMonth - ageYear * 12;
  return { year: ageYear, month: extraMonth };
}

function DetailRow(props) {
  const { icon, title, data, ...rest } = props;
  return (
    <>
      <div className='row' {...rest}>
        <div className='col-4 col-sm-6'>
          <div className='info-title add-padding'>
            {icon} <span>{title}</span>
          </div>
        </div>
        <div className='col-8 col-sm-6'>
          <p className='info-value add-padding'>{data ?? '-'}</p>
        </div>
      </div>
      <hr style={{ borderColor: '#E9F9FF' }} />
    </>
  );
}

function CustomerView(props) {
  const itemId = props.computedMatch.params.id;

  const [data, setData] = useState({});
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  const getItemData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${apiRoute.customer}/${itemId}`);
      let r = res.data.data;
      setData(r.data);
      setCollections(r.collections);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  // const changeItemStatus = async (status) => {
  //   try {
  //     const res = await axios.put(
  //       `${apiRoute.customer}/${itemId}/status-update?status=${status}`
  //     );
  //     notifySuccess(res.data.message);
  //     getItemData();
  //   } catch (err) {
  //     notifyAlert(common.somethingWrongMsg);
  //   }
  // };

  const handleUnderAgeConfirmation = async () => {
    try {
      const res = await axios.post(`${apiRoute.allowUnderage}`, { customer_id: itemId });
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const goToList = () => {
    props.history.push(appRoute.customer);
  };

  const userAge = () => {
    let date = data?.dob?.toString().substring(0, 10);
    if (!date) return;

    return getAge(date);
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToList} style={{ cursor: 'pointer', marginRight: '15px' }} className='action-delete'>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Customer Profile</h5>
              </div>
              <div className='header-right'>
                {/* <div className='header-status'>
                  <span>Status:</span>
                  <div className='toggle'>
                    <div className='material-switch'>
                      <input
                        id={`store_status_${data._id}`}
                        type='checkbox'
                        checked={data.status}
                        onChange={(e) => {
                          changeItemStatus(e.target.checked);
                        }}
                      />
                      <label
                        htmlFor={`store_status_${data._id}`}
                        className='label-default'
                      />
                    </div>
                  </div>
                  {data.status ? (
                    <span className='status-active'>Active</span>
                  ) : (
                    <span>Inactive</span>
                  )}{' '}
                </div> */}
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          {loading && <FsSpinner />}
          <section className='customer-profile d-inline-block position-relative w-100'>
            <div className='container-fluid'>
              {/* ****Profile Box**** */}
              <div className='row'>
                <div className='col-9 mx-auto'>
                  <div className='customer-profile-box '>
                    <div className='customer-profile-box-img'>
                      <div className='img-box'>
                        <RenderImage
                          image={data?.image || customerProfile}
                          class_='img-fluid'
                          style={{ background: '#ffffff' }}
                        />
                      </div>
                    </div>
                    <div className='customer-profile-box-name'>
                      <h5 className='sec-header'>{data.fullname}</h5>
                    </div>

                    <div className='customer-profile-box-info'>
                      <DetailRow title='Gender' data={data.gender} icon={<FasIcon icon='faTransgenderAlt' />} />
                      <DetailRow
                        title='Date of Birth'
                        data={data?.dob && new Date(data.dob).toLocaleDateString()}
                        icon={<FasIcon icon='faClock' />}
                      />
                      <DetailRow
                        title='Age'
                        data={data?.dob && `${userAge()?.year} Year ${userAge()?.month} Month`}
                        icon={<FarIcon icon='faAddressBook' />}
                      />
                      <DetailRow
                        title='Registered Date'
                        data={new Date(data.createdAt).toLocaleString()}
                        icon={<FasIcon icon='faClock' />}
                      />
                      <DetailRow title='Post code' data={data.postcode} icon={<FasIcon icon='faLocationArrow' />} />
                      <DetailRow
                        title='Organizatoin'
                        data={data?.organization?.name}
                        icon={<FarIcon icon='faBuilding' />}
                      />
                      <DetailRow
                        title='Total collected Condoms'
                        data={collections?.length || 0}
                        icon={<FarIcon icon='faCopyright' />}
                      />
                      <DetailRow
                        title='Status'
                        data={data?.status ? 'Active' : 'In Active'}
                        icon={<FarIcon icon='faAddressBook' />}
                      />

                      {data?.isUnderage === true && (
                        <>
                          <div className='row'>
                            <div className='col-4 col-sm-6 '>
                              <div className='info-title add-padding'>
                                <FarIcon icon='faAddressBook' />
                                <span>Contact Detail</span>
                              </div>
                            </div>
                            <div className='col-8 col-sm-6'>
                              <p className='info-value add-padding'>
                                {data?.contactDetail || 'Contact detail is not given'}
                              </p>
                            </div>
                          </div>

                          <hr style={{ borderColor: '#E9F9FF' }} />
                          <div className='row'>
                            <div className='col-4 col-sm-6 '>
                              <div className='info-title add-padding'>
                                <FarIcon icon='faAddressBook' />
                                <span>Underage Status</span>
                              </div>
                            </div>
                            <div className='col-8 col-sm-6'>
                              <p className='info-value add-padding'>{data?.underageStatus.toUpperCase()}</p>
                            </div>
                          </div>

                          {(data?.underageStatus === 'inProcess' || data?.underageStatus === 'pending') && (
                            <>
                              <hr style={{ borderColor: '#E9F9FF' }} />
                              <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                  <button type='button' className='btn-filled' onClick={handleUnderAgeConfirmation}>
                                    Allow To Access Condom Card Program
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <br />
                    <br />

                    {/* Collections */}
                    <div className='w-100 text-center font-weight-bold pb-3 h5'>
                      <u> Condom Collections</u>
                    </div>

                    {(collections || []).length > 0 ? (
                      <>
                        <div className='row'>
                          <div className='col-4 col-sm-6'>
                            <div className='info-title add-padding font-weight-bold'>
                              <span>Store</span>
                            </div>
                          </div>
                          <div className='col-8 col-sm-6'>
                            <p className='info-value add-padding font-weight-bold'>Collection Date</p>
                          </div>
                        </div>
                        <hr style={{ borderColor: '#E9F9FF' }} />

                        <div>
                          {collections.map((item, index) => (
                            <DetailRow
                              title={item?.store?.name}
                              data={new Date(item.createdAt).toLocaleString()}
                              key={`collection_list_${index}`}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className='text-center w-100'>No collection found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default withRouter(CustomerView);
