import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common, validationMsg as vm } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import * as Yup from 'yup';

const initialValues = {
  old_password: '',
  new_password: '',
  password_confirm: '',
};

const validationSchema = Yup.object().shape({
  old_password: Yup.string().min(6, vm.password.min6char).required(vm.old_password.required),
  new_password: Yup.string().min(6, vm.password.min6char).required(vm.new_password.required),
  password_confirm: Yup.string()
    .oneOf([Yup.ref('new_password'), null], vm.password.shouldMatch)
    .required(vm.password_confirm.required),
});

function ChangePassword(props) {
  const goToDashboard = () => {
    props.history.push(appRoute.dashboard);
  };

  const handleForm = async (form_data, { setSubmitting, setErrors, resetForm }) => {
    try {
      const res = await axios.post(apiRoute.resetPassword, form_data);
      notifySuccess(res.data.message);
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
    resetForm();
  };

  return (
    <>
      <div>
        {/* ***Header*** */}
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goToDashboard} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Change Password</h5>
              </div>
            </div>
          </div>
        </header>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleForm}>
          {({ values, isSubmitting }) => (
            <Form>
              <div className='ex-padding'>
                {/* ***add-form*** */}
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='add-form-basic-form'>
                          {/* <h5 className='sec-header'></h5> */}

                          <div className='form-group'>
                            <Field
                              type='password'
                              name='old_password'
                              placeholder='Enter Current Password'
                              component={Input2}
                            />
                          </div>
                          <div className='form-group'>
                            <Field type='password' name='new_password' placeholder='New Password' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field
                              type='password'
                              name='password_confirm'
                              placeholder='Confirm Password'
                              component={Input2}
                            />
                          </div>

                          <FormSubmitBtn isSubmitting={isSubmitting} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default withRouter(ChangePassword);
