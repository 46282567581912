import { validationMsg as vm } from 'utils/constant';
import * as Yup from 'yup';

export const initialValues = {
  icon: null,
  title: '',
  description: '',
};

export const validationSchema = Yup.object().shape({
  icon: Yup.string().nullable().required(vm.icon.required),
  title: Yup.string().required(vm.title.required),
  description: Yup.string().required(vm.description.required),
});
