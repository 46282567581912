import { ErrorMessage, useField } from 'formik';
import React from 'react';

export function Input2({ field, type, ...props }) {
  return (
    <>
      <input type={type || 'text'} className='form-control' {...field} {...props} />

      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}

export function Input({ type, ...props }) {
  const [field] = useField(props);
  return (
    <>
      <input type={type || 'text'} className='form-control' {...field} {...props} />

      <ErrorMessage name={field.name} component='div' className='form-field-error' />
    </>
  );
}
