import axios from 'axios';
import { PrimaryButton } from 'components/helperComp/Buttons';
import { FsSpinner } from 'components/helperComp/Spinner';
import { Header, NoData } from 'components/helperComp/Template';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiRoute, appRoute, common } from 'utils/constant';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import '../../assets/css/organization.scss';
import './quizBank.scss';

function Quizbank({ role }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(apiRoute.quiz);
      const r = res.data.data;
      setData(r.data || []);
      setSelectedQuizzes(r.selectedQuizzes || []);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const quizSelection = async ({ selection }) => {
    setLoading(true);
    try {
      const res = await axios.post('/quiz/selection', { quizzes: selection });
      notifySuccess(res.data.message);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header title='Quiz Bank'>
        <Link to={appRoute.quizbankCreate}>
          <PrimaryButton text='+ Add Quiz' />
        </Link>
      </Header>

      <div className='ex-padding'>
        <section className='quiz-bank d-inline-block opsition-relative w-100'>
          <div className='container-fluid'>
            <Formik initialValues={{ selection: selectedQuizzes }} onSubmit={quizSelection} enableReinitialize={true}>
              {({ values, setFieldValue, submitForm }) => (
                <Form>
                  <div className='row'>
                    {loading && <FsSpinner />}

                    {data.length < 1 && !loading ? (
                      <NoData />
                    ) : (
                      data.map((quiz, index) => (
                        <div
                          className='col-xl-3 col-lg-6 col-md-6 col-sm-6'
                          key={index}
                          style={
                            role === common.adminRoleKey && quiz.createdBy === null
                              ? { marginBottom: '3rem' }
                              : { marginBottom: '1rem' }
                          }
                        >
                          <div className='quiz-bank-box'>
                            <div className='custom-control custom-checkbox image-checkbox quiz-bank-box-img'>
                              <div
                                className={`quiz-bank-box-top d-flex justify-content-between align-items-center ${
                                  role === common.orgRoleKey ||
                                  (role === common.adminRoleKey && quiz.createdBy !== null)
                                    ? 'remove-padding'
                                    : 'add-padding'
                                }`}
                              >
                                {role === common.orgRoleKey && (
                                  <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    id={index}
                                    value={quiz._id}
                                    checked={values.selection.includes(quiz._id)}
                                    onChange={({ target }) => {
                                      let selectedValue = target.checked
                                        ? [...values.selection, target.value]
                                        : [...values.selection].filter((q) => q !== target.value);

                                      setFieldValue('selection', selectedValue);
                                      submitForm();
                                    }}
                                  />
                                )}

                                <label className='custom-control-label' htmlFor={index}>
                                  <div className='quiz-bank-box-text'>
                                    <span>{quiz.name}:</span>
                                    <p>{quiz.description}</p>

                                    {/* show view button for all org quiz */}
                                    {(role === common.orgRoleKey ||
                                      (role === common.adminRoleKey && quiz.createdBy !== null)) && (
                                      <div className='mt-3 d-flex justify-content-center'>
                                        <Link to={`/quizbank/${quiz._id}/view`}>
                                          <button type='button' className='btn-bordered'>
                                            View
                                          </button>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </label>
                              </div>
                            </div>

                            {role === common.adminRoleKey && quiz.createdBy === null && (
                              <div className='quiz-bank-box-profiles' style={{ zIndex: 2 }}>
                                <div className='quiz-bank-box-profiles-left'>
                                  {quiz.org?.length === 0 ? (
                                    <p style={{ color: 'gray' }}>No organization assigned</p>
                                  ) : (
                                    <ul>
                                      {quiz.org.map(
                                        (org, i) =>
                                          i < 2 && (
                                            <li
                                              style={{
                                                width: 'auto',
                                                height: 'auto',
                                                borderRadius: 0,
                                                padding: '0 10px',
                                              }}
                                            >
                                              <p>{org.name}</p>
                                            </li>
                                          )
                                      )}

                                      {quiz.org.length - 2 > 0 && (
                                        <li className='ex-ml' style={{ marginLeft: 0 }}>
                                          <div>{quiz.org.length - 2}+</div>
                                        </li>
                                      )}

                                      {/* {quiz.org.map(
                                          (org, i) =>
                                            i < 6 && (
                                              <li
                                                className={
                                                  i !== 0 ? 'ex-ml' : ''
                                                }
                                                key={`orglogo_${i}`}
                                                style={{ background: 'none' }}
                                              >
                                                {console.log(org)}

                                                <img
                                                  src={
                                                    apiRoute.apiAppBaseRoute +
                                                    org.logo
                                                  }
                                                  className='img-fluid'
                                                  alt=''
                                                />
                                              </li>
                                            )
                                        )}

                                        {quiz.org.length - 5 > 0 && (
                                          <li className='ex-ml'>
                                            <div>{quiz.org.length - 5}+</div>
                                          </li>
                                        )} */}
                                    </ul>
                                  )}
                                </div>
                                <div className='quiz-bank-box-profiles-right'>
                                  <Link to={`/quizbank/${quiz._id}/view`}>
                                    <button type='button' className='btn-bordered'>
                                      View
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </section>
      </div>
    </>
  );
}

const mstp = (state) => ({
  role: Typy(state, 'auth.role', common.orgRoleKey),
});

export default connect(mstp)(Quizbank);
