import logoSvgImg from 'assets/images/defaultLogo.svg';
import logoImg from 'assets/images/Logo_Login.png';
import RenderImage from 'components/helperComp/RenderImage';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function AuthLayout({ children }) {
  return (
    <>
      <Helmet>
        <link rel='icon' href={logoSvgImg} id='favicon' />
        <title>MCC</title>
      </Helmet>
      <div className='login-page-container'>
        <section className='get-in-touch'>
          <div className='get-in-touch-title'>
            <RenderImage image={logoImg} class_='img-fluid' />
          </div>
          {children}
        </section>
      </div>
    </>
  );
}
