import React from 'react';
import { FasIcon } from './FontAwesomeIcon';

export function Header(props) {
  const { title, children } = props;

  return (
    <header className='header' id='header'>
      <div className='container-fluid'>
        <div className='header-content'>
          <div className='header-left'>
            <h5>{title}</h5>
          </div>
          <div className='header-right'>{children}</div>
        </div>
      </div>
    </header>
  );
}

export function NoData() {
  return (
    <div className='d-flex align-items-center w-100 justify-content-center' style={{ minHeight: '150px' }}>
      <div className='table-data-not-found'>
        <FasIcon icon='faExclamationCircle' className='h5 text-secondary' />
        <span className='ml-3 h5 text-secondary'>No Data Found</span>
      </div>
    </div>
  );
}

export function Modal(props) {
  const { heading, closeModal, children } = props;

  return (
    <>
      <div className='modal fade show org-modal' style={{ display: 'block', paddingright: '15px' }}>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content' style={{ paddingBottom: '1rem' }}>
            <div className='modal-header' style={{ paddingBottom: '10px' }}>
              <p className='modal-title sec-header'>{heading}</p>
              <button type='button' className='close' onClick={closeModal}>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div style={{ padding: '10px' }}>{children}</div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  );
}
