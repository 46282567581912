import React from 'react';
import defaultImage from '../../assets/images/addImage.png';

function addDefaultSrc(ev) {
  ev.target.src = defaultImage;
}

export default function RenderImage({ image, class_, ...props }) {
  return <img className={class_ || 'menu-image'} src={image} alt='' onError={addDefaultSrc} {...props} />;
}

// function imageExists(image_url) {
//   var http = new XMLHttpRequest();
//   http.open('HEAD', image_url, false);
//   http.send();
//   return http.status != 404;
// }

// export default function RenderImage({ image, class_, ...props }) {
//   return imageExists(image) ? (
//     <img className={class_ || 'menu-image'} src={image} alt='' {...props} />
//   ) : (
//     <img
//       className={class_ || 'menu-image'}
//       src={defaultImage}
//       alt=''
//       {...props}
//     />
//   );
// }
