import back_arrow from 'assets/images/icon/back arrow page.png';
import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import { Select } from 'components/core/Form/Select';
import FormSubmitBtn from 'components/helperComp/FormSubmitBtn';
import RenderImage from 'components/helperComp/RenderImage';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import { initialValues, moduleName, validationSchema, validationSchemaOrg } from './helper';

function NotificationSend(props) {
  const [orgs, setOrgs] = useState([]);

  const role = useSelector((state) => state?.auth?.role || common.orgRoleKey);

  const goBack = () => {
    props.history.push(appRoute.notification);
  };

  const handleForm = async (values, { setSubmitting, setErrors }) => {
    try {
      const res = await axios.post(apiRoute.notificationSend, values);
      notifySuccess(res.data.message);
      goBack();
    } catch (err) {
      const e = err.response;
      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
    }
  };

  const getOrgs = async () => {
    try {
      const res = await axios.get(apiRoute.organization + '/all');
      const orgs = res.data.data;

      setOrgs(orgs.map((o) => ({ title: o.name, value: o._id })));
    } catch (error) {}
  };

  useEffect(() => {
    role === common.adminRoleKey && getOrgs();
  }, [role]);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <div onClick={goBack} style={{ cursor: 'pointer', marginRight: '15px' }}>
                  <RenderImage image={back_arrow} class_='img-fluid' />
                </div>
                <h5>Send {moduleName}</h5>
              </div>
            </div>
          </div>
        </header>
        <div className='ex-padding'>
          <Formik
            initialValues={initialValues}
            validationSchema={role === common.adminRoleKey ? validationSchema : validationSchemaOrg}
            onSubmit={handleForm}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form>
                <section className='add-form pt-0'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-8'>
                        <div className='add-form-basic-form'>
                          {role === common.adminRoleKey && (
                            <div className='form-group'>
                              <Field
                                name='organizationId'
                                placeholder='Select an Organization'
                                options={orgs}
                                component={Select}
                              />
                            </div>
                          )}

                          <div className='form-group'>
                            <Field name='title' placeholder='Title' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <Field name='description' placeholder='Description' component={Input2} />
                          </div>
                          <div className='form-group'>
                            <label>Schedule to</label>
                            <Field type='datetime-local' name='datetime' placeholder='Schedule' component={Input2} />
                          </div>

                          <FormSubmitBtn text='Send' isSubmitting={isSubmitting} disabled={!(isValid && dirty)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default withRouter(NotificationSend);
