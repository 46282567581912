import { validationMsg as vm } from 'utils/constant';
import * as Yup from 'yup';

export const commonColumn = [
  {
    title: 'Title',
    key: 'title',
  },
  {
    title: 'Description',
    key: 'description',
  },
  {
    title: 'Created at',
    key: 'createdAt',
    isDate: true,
  },
];

export const moduleName = 'Notification';

export const initialValues = {
  organizationId: '',
  title: '',
  description: '',
  datetime: '',
};

export const commonSchema = {
  title: Yup.string().required(vm.title.required),
  description: Yup.string().required(vm.description.required),
  datetime: Yup.string().required(vm.schedule.required),
};

export const validationSchema = Yup.object().shape({
  organizationId: Yup.string().required(vm.organization.required),
  ...commonSchema,
});

export const validationSchemaOrg = Yup.object().shape({
  ...commonSchema,
});
