import back_arrow from 'assets/images/icon/back arrow page.png';
import Delete_Action from 'assets/images/icon/Delete_Action.png';
import axios from 'axios';
import TextArea from 'components/core/Form/TextArea';
import { FasIcon } from 'components/helperComp/FontAwesomeIcon';
import RenderImage from 'components/helperComp/RenderImage';
import { FsSpinner } from 'components/helperComp/Spinner';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { apiRoute, appRoute, common } from 'utils/constant';
import { getErrorObj } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import CheckSvg from '../../assets/images/CheckSvg.svg';
import { initialValuesQue, validationSchemaRegQuestion } from './helper';

function OrganizationRegQuestions(props) {
  const itemId = props.computedMatch.params.id;
  const formRef = useRef();

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const deleteQuestion = async (id) => {
    setLoading(true);

    try {
      const res = await axios.delete(apiRoute.regQuestion, {
        data: {
          organizationId: itemId,
          questionId: id,
        },
      });
      notifySuccess(res.data.message);
      getItemData();
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const goBack = () => props.history.push(appRoute.organization);

  const handleFormSubmit = async (formdata, { setSubmitting, setErrors, resetForm }) => {
    formdata['organizationId'] = itemId;
    try {
      const res = await axios.post(apiRoute.regQuestion, formdata);
      notifySuccess(res.data.message);
      getItemData();
      resetForm();
    } catch (err) {
      const e = err.response;

      if (e) {
        switch (e.status) {
          case 422:
            setErrors(getErrorObj(e.data.errors));
            break;
          default:
            break;
        }
      } else {
        notifyAlert(common.somethingWrongMsg);
      }
      setSubmitting(false);
      setFormSubmitting(false);
    }
  };

  const getItemData = async () => {
    setLoading(true);

    try {
      const res = await axios.get(`${apiRoute.regQuestion}/${itemId}`);
      let r = res.data.data;

      setQuestions(r.data);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <>
      <div className='main-content'>
        <div className='main-content-left'>
          {/* ***Header*** */}
          <header className='header' id='header'>
            <div className='container-fluid'>
              <div className='header-content'>
                <div className='header-left'>
                  <div onClick={goBack} style={{ cursor: 'pointer', marginRight: '15px' }}>
                    <RenderImage image={back_arrow} class_='img-fluid' />
                  </div>
                  <h5>Add Questions</h5>
                </div>
              </div>
            </div>
          </header>

          <div className='ex-padding'>
            {loading && <FsSpinner />}

            <section className='add-quiz d-inline-block position-relative w-100 '>
              <div className='row'>
                <div className='col-12 form'>
                  <Formik
                    validationSchema={validationSchemaRegQuestion}
                    initialValues={initialValuesQue}
                    onSubmit={handleFormSubmit}
                    innerRef={formRef}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form>
                        <>
                          <div className='from-row-box'>
                            <div className='form-group form-row-left'>Q:</div>
                            <div className='form-group form-row-right'>
                              <TextArea
                                name='title'
                                rows={3}
                                placeholder='Enter Question'
                                className='form-control w-100 textarea'
                              />
                            </div>
                          </div>

                          <div className='from-row-box'>
                            <div className='form-group form-row-left' style={{ width: '150px' }}>
                              Has Extra Input?
                            </div>

                            <div className='toggle'>
                              <div className='material-switch'>
                                <input
                                  id={`org_status_XXXX`}
                                  type='checkbox'
                                  defaultChecked={values.hasTextField}
                                  onChange={(e) => {
                                    if (e.target.checked === true) {
                                      setFieldValue('hasTextField', true);
                                    } else {
                                      setFieldValue('hasTextField', false);
                                    }
                                  }}
                                />
                                <label htmlFor={`org_status_XXXX`} className='label-default' />
                              </div>
                            </div>
                          </div>

                          <FieldArray
                            name='options'
                            render={(arrayHelpers) => (
                              <div>
                                {values.options.map((friend, index) => (
                                  <div className='from-row-box' key={`questions${index}`}>
                                    <div className='form-group form-row-left'>
                                      <div className='custom-control custom-radios image-radio'>
                                        <input type='radio' id={`que_${index}`} name={`answer_${index}`} disabled />
                                        <label htmlFor={`que_${index}`}>
                                          <span>
                                            <RenderImage image={CheckSvg} class_='img-fluid' />
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                    <div key={index} className='form-group form-row-right'>
                                      <Field
                                        name={`options.${index}`}
                                        className='form-control'
                                        placeholder={`Enter option ${index + 1}`}
                                      />

                                      <ErrorMessage
                                        name={`options.${index}`}
                                        component='div'
                                        className='form-field-error'
                                      />
                                    </div>
                                  </div>
                                ))}

                                <div
                                  onClick={() => arrayHelpers.push('')}
                                  style={{
                                    padding: '8px',
                                    backgroundColor: '#f9fdff',
                                    border: '2px solid #1c42da',
                                    borderRadius: '50%',
                                    height: '35px',
                                    width: '35px',
                                    margin: '1rem auto 2rem auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FasIcon
                                    icon='faPlus'
                                    style={{
                                      color: '#1c42da',
                                      fontSize: '25px',
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          />

                          <button type='submit' className='btn-filled mx-auto mt-2' disabled={isSubmitting}>
                            Save
                          </button>
                        </>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* All questions list */}
        <div className='main-content-right'>
          {(questions || []).map((que, index) => (
            <div className='quiz-que-box' key={`questions_${index}`}>
              <div className='from-row-box que'>
                <div className='form-group form-row-left '>
                  <span>Q{index + 1}:</span>
                </div>
                <div className='form-group form-row-right  ml-2'>
                  <p>{que.title}</p>
                </div>
                <div className='form-row-menu'>
                  <div className='menu-action'>
                    <a className='action-delete' onClick={() => deleteQuestion(que._id)}>
                      <RenderImage image={Delete_Action} class_='img-fluid' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='radio-group'>
                {(que.options || []).map((opt, index2) => (
                  <div className='from-row-box' key={`questions_list_${index2}`}>
                    <div className='form-group form-row-left'>
                      <div className='custom-control custom-radios image-radio'>
                        <input
                          type='radio'
                          id={`que_${index}_${index2}`}
                          name={`answer_${index}`}
                          value={index2}
                          disabled
                        />
                        <label htmlFor={`que_${index}_${index2}`}>
                          <span>
                            <RenderImage image={CheckSvg} class_='img-fluid' />
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='form-group form-row-right'>
                      <p className='q-text'>{opt}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className='form-group form-row-right ml-2'>
                <p className='q-text'>Has Extra Input: {que.hasTextField ? 'Yes' : 'No'}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default withRouter(OrganizationRegQuestions);
