import axios from 'axios';
import { Input2 } from 'components/core/Form/Input';
import Table from 'components/core/Table';
import { PrimaryButton } from 'components/helperComp/Buttons';
import { FarIcon } from 'components/helperComp/FontAwesomeIcon';
import { SpinnerComp } from 'components/helperComp/Spinner';
import { Field, Form, Formik } from 'formik';
import { maxBy } from 'lodash-es';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { apiRoute, common } from 'utils/constant';
import { downloadCSV } from 'utils/exportCsv';
import { Typy } from 'utils/helper';
import { notifyAlert, notifySuccess } from 'utils/notify';
import CustomerFilter from './CustomerFilter';
import { exportCustomerHeaders, exportDataInitialValue, exportDataValidationSchema, StatusDot } from './helper';

function CustomerIndex() {
  const role = useSelector((state) => Typy(state, 'auth.role', common.orgRoleKey));

  const [data, setData] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [search, setSearch] = useState('&search=');
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [exportDataModal, setExportDataModal] = useState(false);
  const [exportDataLoading, setExportDataLoading] = useState(false);

  const commonColumn = [
    // {
    //   title: 'Gender',
    //   key: 'gender',
    // },
    {
      title: 'Under Age User',
      key: 'isUnderage',
      render: (record) => (record.isUnderage === true ? <StatusDot color='green' /> : <StatusDot color='red' />),
    },
    {
      title: 'Blocked User',
      key: 'isDeviceBlocked',
      render: (record) => {
        return record.isDeviceBlocked === true ? <StatusDot color='green' /> : <StatusDot color='red' />;
      },
    },
    {
      title: 'Claims',
      key: 'claims',
    },
    {
      title: 'Status',
      key: '',
      className: 'toggle',
      render: (record) => (
        <div className='material-switch'>
          <input
            key={`customer_status_${record._id}`}
            id={`customer_status_${record._id}`}
            type='checkbox'
            defaultChecked={record.status}
            onChange={(e) => {
              changeStatus(record._id, e.target.checked);
            }}
          />
          <label htmlFor={`customer_status_${record._id}`} className='label-default' />
        </div>
      ),
    },
    {
      title: 'Block',
      key: '',
      className: 'toggle',
      render: (record) => (
        <div className='material-switch'>
          <input
            key={`customer_block_${record._id}`}
            id={`customer_block_${record._id}`}
            type='checkbox'
            defaultChecked={record.isDeviceBlocked}
            onChange={(e) => {
              changeBlock(record._id, e.target.checked);
            }}
          />
          <label htmlFor={`customer_block_${record._id}`} className='label-default' />
        </div>
      ),
    },
    {
      title: 'Action',
      key: '',
      className: 'action',
      render: (record) => (
        <>
          <Link to={`/customer/${record._id}/view`}>
            <div className='view'>
              <FarIcon icon='faEye' />
            </div>
          </Link>
          <div>
            <svg
              onClick={() => deleteData(record._id)}
              xmlns='http://www.w3.org/2000/svg'
              x='0px'
              y='0px'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='red'
              className='delete-icon'
            >
              <path d='M 10.806641 2 C 10.289641 2 9.7956875 2.2043125 9.4296875 2.5703125 L 9 3 L 4 3 A 1.0001 1.0001 0 1 0 4 5 L 20 5 A 1.0001 1.0001 0 1 0 20 3 L 15 3 L 14.570312 2.5703125 C 14.205312 2.2043125 13.710359 2 13.193359 2 L 10.806641 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z'></path>
            </svg>
          </div>
        </>
      ),
    },
  ];

  const column =
    role === common.adminRoleKey
      ? [
          {
            title: 'Customer Name',
            key: 'fullname',
            render: (record) => <strong>{record.fullname}</strong>,
          },
          { title: 'Email', key: 'email' },
          {
            title: 'Org. Name',
            key: 'organization.name',
          },
          ...commonColumn,
        ]
      : [
          {
            title: 'Customer Name',
            key: 'fullname',
            render: (record) => <strong>{record.fullname}</strong>,
          },
          { title: 'Email', key: 'email' },
          ...commonColumn,
        ];

  const getData = async (args) => {
    setLoading(true);
    const { page, limit, search: search_ } = args || {};

    try {
      const res = await axios.get(
        `${apiRoute.customer}?page=${page || pagination.page}&limit=${limit || pagination.limit}${
          search_ ?? localStorage?.search
        }`
      );
      // const docs = res.data.data.docs;
      const r = res.data.data;
      const { docs, ...pagination_ } = r;
      setData(docs);
      setPagination(pagination_);
      setLoading(false);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const deleteData = async (data) => {
    try {
      const res = await axios.post(`super-access/delete-customer`, { customer_id: data });
      notifySuccess(res.data.message);
      setLoading(false);
      setIsDelete(!isDelete);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeStatus = async (id, status) => {
    try {
      const res = await axios.put(`${apiRoute.customer}/${id}/status-update?status=${status}`);
      notifySuccess(res.data.message);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const changeBlock = async (id, block) => {
    try {
      const res = await axios.put(`${apiRoute.customer}/${id}/block-update?isDeviceBlocked=${block}`);
      notifySuccess(res.data.message);
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    }
  };

  const handleExportData = async (values) => {
    try {
      setExportDataLoading(true);

      const res = await axios.get(
        `${apiRoute.customer}/export-data?start_date=${values.start_date}&end_date=${values.end_date}${search}`
      );
      const r = res.data.data;

      const registerQuestions = maxBy(r, 'registerQuestions')?.registerQuestions;

      const dynamicColumn = (registerQuestions || []).map((item, index) => ({
        key: `registerQuestions.${index}.answer`,
        label: item.title,
      }));

      let data = [];

      r.forEach((item, index) => {
        const {
          fullname,
          gender,
          email,
          dob,
          postcode,
          contactDetail,
          collection,
          isUnderage,
          overage,
          isDeviceBlocked,
          underageStatus,
          status,
          createdAt,
          collectionData,
          registerQuestions,
        } = item;

        data.push({
          index: index + 1,
          fullname,
          gender,
          dob: moment(dob).format('Do MMMM YYYY'),
          email,
          postcode,
          contactDetail,
          collection,
          isUnderage,
          overage,
          isDeviceBlocked,
          underageStatus,
          status,
          createdAt: moment(createdAt).format('Do MMMM YYYY, h:mm:ss a'),
          registerQuestions,
          collectionData: {
            store: '',
            createdAt: '',
          },
        });

        (collectionData || []).forEach((item, index) => {
          data.push({
            collectionData: {
              index: index + 1,
              store: item?.store?.name,
              createdAt: moment(item?.createdAt).format('Do MMMM YYYY, h:mm:ss a'),
            },
          });
        });
      });

      downloadCSV(data, exportCustomerHeaders(dynamicColumn), 'Customers');
    } catch (err) {
      notifyAlert(common.somethingWrongMsg);
    } finally {
      setExportDataLoading(false);
    }
  };

  const getOrgs = async () => {
    try {
      const res = await axios.get(apiRoute.organization + '/all');
      const orgs = res.data.data;

      setOrgs([{ title: 'All', value: '' }, ...orgs.map((o) => ({ title: o.name, value: o._id }))]);
    } catch (error) {}
  };

  const handleExportDataModal = () => {
    setExportDataModal((preSatate) => !preSatate);
  };

  useEffect(() => {
    role === common.adminRoleKey && getOrgs();
  }, [role]);

  useEffect(() => {
    // if (search !== '&search=') {
    localStorage.setItem('search', search);
    // }
    getData();
  }, [search, isDelete]);

  console.log('search', search);

  return (
    <>
      <div>
        <header className='header' id='header'>
          <div className='container-fluid'>
            <div className='header-content'>
              <div className='header-left'>
                <h5>
                  Customer <span>({(data || []).length})</span>
                </h5>
              </div>
              <div className='header-right'>
                {role === common.orgRoleKey && (
                  <PrimaryButton text='Export Data' onClick={() => setExportDataModal(true)} />
                )}
              </div>
            </div>
          </div>
        </header>

        <div style={{ display: 'flex', padding: '20px 35px', flexDirection: 'column' }}>
          <CustomerFilter setFilter={setSearch} orgs={orgs} />
        </div>

        <div className='ex-padding'>
          <Table
            columns={column}
            dataSource={data}
            pagination={pagination}
            onPageChange={(page) => getData({ page })}
            onPerPageChange={(limit) => getData({ limit })}
            loading={loading}
          />
        </div>
      </div>

      <Modal isOpen={exportDataModal} toggle={handleExportDataModal} backdrop='static' centered>
        <ModalHeader toggle={handleExportDataModal}>Export customer's data</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={exportDataInitialValue}
            validationSchema={exportDataValidationSchema}
            onSubmit={handleExportData}
          >
            {() => (
              <Form>
                <div className='row'>
                  <div className='col-6'>
                    <label> Start date </label>
                    <Field type='date' name='start_date' placeholder='Start date' component={Input2} />
                  </div>
                  <div className='col-6'>
                    <label> End date </label>
                    <Field type='date' name='end_date' placeholder='End date' component={Input2} />
                  </div>
                </div>

                <div className='d-flex justify-content-end mt-4'>
                  <PrimaryButton
                    text={
                      exportDataLoading ? (
                        <SpinnerComp
                          style={{
                            position: 'relative',
                            margin: 'auto auto',
                            top: 0,
                            left: 0,
                            height: '25px',
                            width: '25px',
                          }}
                        />
                      ) : (
                        'Export'
                      )
                    }
                    type='submit'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
}

export default CustomerIndex;
