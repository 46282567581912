import * as Yup from 'yup';

export function StatusDot({ color }) {
  return <div style={{ background: color, width: '10px', height: '10px', borderRadius: '50%' }}></div>;
}

export function FilterInput(props) {
  const { field, form, placeholder, ...rest } = props;

  return (
    <div className='filter-data-input-container mr-3 text-center'>
      <label htmlFor={field.name}>{placeholder}</label>
      <div>
        <input style={{ padding: '10px 15px' }} id={field.name} {...field} {...rest} placeholder={placeholder} />
      </div>
    </div>
  );
}

export function FilterSelect({ field, type, options, placeholder, ...props }) {
  return (
    <>
      <div className='filter-data-input-container mr-3 text-center'>
        <label htmlFor={field.name}>{placeholder}</label>

        <select
          name={field.name}
          id={field.name}
          className='form-control dropdown-toggle org-name'
          style={{ height: '50px', padding: '10px 15px', minWidth: '11rem' }}
          {...field}
          {...props}
        >
          {(options || []).map((opt, i) => (
            <option value={opt.value} className='dropdown-item org' key={`${opt.value}_${i}`}>
              {opt.title}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export const exportCustomerHeaders = (dynamicColumn) => [
  { key: 'index', label: 'Sr.No.' },
  { key: 'fullname', label: 'Fullname' },
  { key: 'gender', label: 'Gender' },
  { key: 'dob', label: 'Birthdate' },
  { key: 'email', label: 'Email' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'contactDetail', label: 'Contact Details' },
  { key: 'collection', label: 'Collection' },
  { key: 'isUnderage', label: 'Is underage?' },
  { key: 'overage', label: 'Is above 25?' },
  { key: 'isDeviceBlocked', label: 'Is device blocked?' },
  { key: 'underageStatus', label: 'Underage user action' },
  { key: 'status', label: 'Status', boolean: true },
  { key: 'createdAt', label: 'Date Registered' },
  ...dynamicColumn,

  { key: '', label: '' },
  { key: '', label: '' },
  { key: 'collectionData.index', label: 'Sr.No.' },
  { key: 'collectionData.store', label: 'Store Name' },
  { key: 'collectionData.createdAt', label: 'Collection Date' },
];

export const customerFilterValidationSchema = Yup.object().shape({
  search: Yup.string(),
  organization: Yup.string(),
  underage: Yup.string(),
  overage: Yup.string(),
  blocked: Yup.string(),
  status: Yup.string(),
});

export const customerFilterInitialValues = {
  search: '',
  organization: '',
  underage: '',
  overage: '',
  blocked: '',
  status: '',
};

export function objToQueryString(obj) {
  let str = '';

  for (let key in obj) {
    str += `&${key}=${obj[key]}`;
  }

  return str;
}

export const filterStatus = [
  { title: 'All', value: '' },
  { title: 'Active', value: 'true' },
  { title: 'Inactive', value: 'false' },
];

export const filterBlocked = [
  { title: 'All', value: '' },
  { title: 'Blocked', value: 'true' },
  { title: 'Not blocked', value: 'false' },
];

export const filterUnderage = [
  { title: 'All', value: '' },
  { title: 'Underage', value: 'true' },
  { title: 'Not Underage', value: 'false' },
];

export const filterOverage = [
  { title: 'All', value: '' },
  { title: 'Overage', value: 'true' },
];

export const exportDataInitialValue = {
  start_date: '',
  end_date: '',
};

export const exportDataValidationSchema = Yup.object().shape({
  start_date: Yup.string(),
  end_date: Yup.string(),
});
